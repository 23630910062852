import React from "react";

import { connect } from "react-redux";
import * as Actions from "../../../store/actions";

import { withFirebase } from "../../../Firebase";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import * as ROUTES from "../../../routes";
import { withRouter } from "react-router-dom";

import { Database, GDApi, Tag } from "../../Utils";
import NoteListView from "./NoteListView";
import EmptyNoteBook from "./EmptyNoteBook"

import { CircularProgress } from "@material-ui/core";

const propTypes = {
};

const defaultProps = {};

class NoteListModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteUpdated: false
    };
  }

  goTimeLineView = () => {
    let withdown = isWidthDown("sm", this.props.width);
    if (withdown) {
      this.props.handleDrawer(false);
    }
    this.props.selectNote(null);
    this.props.history.push(ROUTES.MAIN_TIMELINE);
  };

  selectNote = note => {
    let withdown = isWidthDown("sm", this.props.width);
    if (withdown) {
      this.props.handleDrawer(false);
    }
    if (note.notetype === "drive") {
      this.props.selectNote(note);
      this.props.history.push(ROUTES.MAIN_PAGE_LIST_DRIVE);
    } else if (note.notetype === "firebase") {
      this.props.selectNote(note);
      this.props.history.push(ROUTES.MAIN_PAGE_LIST);
    } else {
      console.error("not support note type", note);
    }
  };

  deleteNote = note => {
    console.log("delete note", note);
    let noteid = note.noteid;
    Tag.googleDriveDelete(note.note)
    Database.deleteNoteBatch(noteid)
      .then(() => {
        console.log("delete note complete", note.note);
        this.noteUpdate();
        this.props.history.push(ROUTES.MAIN_PAGE_LIST_DRIVE);
      })
      .catch(err => console.log("delete note error", err));
  };

  render() {
    const { expand, notes } = this.props;
    const {noteUpdated} = this.state;
  
    if (expand && notes.length === 0) {
      return noteUpdated ? < EmptyNoteBook /> : <CircularProgress style={{margin: 'auto', marginTop: 20, marginBottom: 10}}/>;
    }
    return (
      <NoteListView
        expand={expand}
        goTimeLineView={this.goTimeLineView}
        selectNote={this.selectNote}
        notes={notes}
        user={this.props.user}
        currentnote={this.props.currentnote}
        deleteNote={this.deleteNote}
      />
    );
  }
  shouldComponentUpdate(nextProps, nextState){
    const {expand, user , notes} = this.props;
    if (!expand && nextProps.expand && GDApi.ready() && user &&  notes.length === 0) {
      this.noteUpdate();
    }
    return true

  }

  noteUpdate = () => {
    console.log("note list update start");
    let dbnote = Database.getNoteList();
    let drivenote = GDApi.getAllNotes2();
    Promise.all([dbnote, drivenote])
      .then(notes => {
        let dbarr = notes[0]
        let gdarr = notes[1]
        console.log("drive", gdarr);
        console.log("firebase", dbarr);

        if (gdarr && dbarr) {
          let noteArray = [];

          gdarr.forEach(gdnote => {
            let note = dbarr.find(dbnote => gdnote.id === dbnote.gdid);
            if (note) {
              let noteData = note;
              noteData.updated = note.updated;
              noteArray.push(noteData);
            } else {
              let noteData = gdnote;
              let addData = {
                note: 0,
                noteid: gdnote.id,
                gdid: gdnote.id,
                owner: 27,
                section: 3,
                thumnail: null,
                uid: "",
                usingPage: 0,
                notetype: "drive",
                title: gdnote.parentsName,
                updated: gdnote.modifiedTime
              };
              Object.assign(noteData, addData);
              noteArray.push(noteData);
            }
          });
          console.log("update note list", noteArray);
          this.setState({ noteUpdated: true });
          this.props.onNotes(noteArray);
        }
      })
      .catch(e => {
        console.log("error", e);
        this.setState({ noteUpdated: true });
      });
  };
}

NoteListModel.propTypes = propTypes;
NoteListModel.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    user: state.login.user,
    currentnote: state.note.currentNote,
    notes: state.notes.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNotes: notes => dispatch(Actions.noteList(notes)),
    selectNote: note => dispatch(Actions.changeNote(note)),
    handleDrawer: (open) => dispatch(Actions.drawer(open))
  };
};

const withNoteListModel = withWidth()(withRouter(withFirebase(NoteListModel)));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNoteListModel);
