import * as types from '../actions/ActionTypes'

const initialState = {
  next: true,
  count: 0
}

export default function movepage(state = initialState, action) {
  switch (action.type) {
    case types.MOVE_PAGE:
      return {next: action.isNext, count: state.count+1}
    default:
      return state
  }
}