var CLIENT_ID = '693506452621-l7ndv2fh6noo1dhbua3p2rqp6cdqglke.apps.googleusercontent.com';
var API_KEY = 'AIzaSyBnhq5da3LZGLac67xYCpZhVKz-4hmqJmg';

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES = 'https://www.googleapis.com/auth/drive';

var firebase = null

var initCallback = null

export function initGoogleDrive(fb, callback) {
  initCallback = callback
  console.log("google drive init with firebase");
  firebase = fb
  window.gapi.load('client:auth2', initClient);
};

function initClient() {
  let update = updateSigninStatus
  window.gapi.client.init({
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES
  }).then(function () {
    // Listen for sign-in state changes.
    window.gapi.auth2.getAuthInstance().isSignedIn.listen(update);

    // Handle the initial sign-in state.
    update(window.gapi.auth2.getAuthInstance().isSignedIn.get());
  }, function(error) {
    console.log(error);
  });
}


function updateSigninStatus(isSignedIn){
  if (isSignedIn) {
    console.log("sign in success")
    let googleUser = window.gapi.auth2.getAuthInstance().currentUser.get()
    console.log("google drive user", googleUser)
    let authresult = googleUser.getAuthResponse()
    // console.log("authresult", authresult)

    var idToken = authresult.id_token;
    // console.log("idtoken", idToken)
    var creds = firebase.GoogleAuthProvider().credential(idToken);
    // console.log("credential", creds)
       // auth in the user 
    firebase.Auth().signInWithCredential(creds).then((user) => {
        // you can use (user) or googleProfile to setup the user
        // var googleProfile = googleUser.getBasicProfile()
        if (user) {
          console.log("firebase user", user)
          // do something with this user
          initCallback(true)
        }
    })

    // var profile = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    // console.log('ID: ' + profile.getId());
    // console.log('Full Name: ' + profile.getName());
    // console.log('Given Name: ' + profile.getGivenName());
    // console.log('Family Name: ' + profile.getFamilyName());
    // console.log('Image URL: ' + profile.getImageUrl());
    // console.log('Email: ' + profile.getEmail());
  } else {
    console.log("sign out")
    if (initCallback) {
      console.log("sign googledriveSignoutCallback")
      initCallback(false)
    }
  }
}

export function ready() {
  if (window.gapi){
    try {
      let files = window.gapi.client.drive.files
      if(files) return true
    } catch {
      return false
    }
  } 
  return false
}

export function login() {
  if (window.gapi) {
    window.gapi.auth2.getAuthInstance().signIn();
  } else {
    console.log("not load api")
  }
}

export function logout() {
  if (window.gapi) {
    window.gapi.auth2.getAuthInstance().signOut();
  }  else {
    console.log("not load api")
  }
}

export function apilist() {
  let files =   window.gapi.client.drive.files
  console.log(files)
}

export function getRootfolder() {
  let q = "name = 'NeoNotes(DO NOT MODIFY)' and 'root' in parents and mimeType = 'application/vnd.google-apps.folder'"
  let request = window.gapi.client.drive.files.list({q})
  return request
}

export function getlist(id) {
  let q =  "'" + id + "' in parents"
  let fields = "kind,nextPageToken,files(id,mimeType,name,kind, version, properties, appProperties, contentHints,size, modifiedTime, parents)"
  let request = window.gapi.client.drive.files.list({q, fields})
  return request
}

export function download(id) {
  let fileId = id
  return window.gapi.client.drive.files.get({fileId, alt:'media'})
}

export function upload() {
  console.log("upload start")
  var fileMetadata = {
    'name': 'plain.text'
  };
  let data = "abcd"
  var media = {
    mimeType: 'plain/text',
    body: data,
    name: 'test01.txt'
  };
  window.gapi.client.drive.files.create({
    resource: fileMetadata,
    media: media,
    fields: 'id'
  }).then (file => {
      console.log('File Id: ', file.id);
  }).catch(err => console.log(err))
}


/**
 * @return Promise: filelist(Note)
 */
export function getAllNotes(){
  return new Promise( (resolve, reject) => {
    if (!ready()) {
      console.log("google api not ready")
      reject("google api not ready")
      return
    }
    let parentsNames = {}
    getRootfolder()
    .then(res => {
      console.log("Google API Files Search Start");
      var files = res.result.files;
      if (files && files.length > 0) {
        let root = files[0];
        // console.log(files);
        return this.getlist(root.id);
      } else {
        console.log("No files found.");
        reject("No files found.")
      }
    })
    .then(res => {
      let files = res.result.files;
      let arrayOfPromises = []
      files.forEach( file => {
        if (file.mimeType === "application/vnd.google-apps.folder") {
          parentsNames[file.id] = file.name
          arrayOfPromises.push (getlist(file.id))
        }
      })
      return Promise.all(arrayOfPromises)
    })
    .then( allfiles => {
      // console.log(allfiles)
      let zipfiles = []
      allfiles.forEach( res => {
        let files = res.result.files
        files.forEach (file => {
          if (file.mimeType === "application/zip" && file.name.includes('notes')) {
            file.parentsName = parentsNames[file.parents[0]]
            zipfiles.push(file)
          }
        })
      })
      // console.log(zipfiles)
      resolve(zipfiles)
    })
    .catch(err => {
      console.log(err)
      reject(err)
    });
  })
}

/** 
 * @return Promise: filelist(Note)
 */
export function getAllNotes2(){
  return new Promise( (resolve, reject) => {
    if (!ready()) {
      console.log("google api not ready")
      reject("google api not ready")
      return
    }
    let parentsNames = {}
    getRootfolder()
    .then(res => {
      console.log("Google API Files Search Start");
      var files = res.result.files;
      if (files && files.length > 0) {
        let root = files[0];
        // console.log(files);
        return this.getlist(root.id);
      } else {
        console.log("No files found.");
        reject("No files found.")
      }
    })
    .then(res => {
      let files = res.result.files;
      files.forEach( file => {
        if (file.mimeType === "application/vnd.google-apps.folder") {
          parentsNames[file.id] = file.name
        }
      })
      let q =  "name contains '.neonotes' and mimeType = 'application/zip' and trashed=false"
      let fields = "kind,nextPageToken,files(id,mimeType,name,kind, version, properties, appProperties, contentHints,size, modifiedTime, parents)"
      let request = window.gapi.client.drive.files.list({q, fields})
      return request
    })
    .then( res => {
      let files = res.result.files
      console.log("googledrive files: ", files)

      let zipfiles = []
      files.forEach( file => {
        if (file.parents){
          const name = parentsNames[file.parents[0]]
          if (name) {
            file.parentsName = name
            zipfiles.push(file)
          }
        }
      })
      // console.log(zipfiles)
      resolve(zipfiles)
    })
    .catch(err => {
      console.log(err)
      reject(err)
    });
  })

}