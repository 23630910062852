import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Route } from "react-router-dom";
import * as ROUTES from "../routes";

import {drawerWidth} from './config'
import clsx from 'clsx';

//Routing USE
import MainView from "../views/Setting/MainView";
import PageDetailModel from'../views/PageDetail/PageDetailModel'
import TimeLineModel from '../views/TimeLine/TimeLineModel'
import PageListModel from '../views/PageList/PageListModel'
import DriveListModel from "../views/PageList/DriveList/DriveListModel";
import SettingView from '../views/Setting/SettingView'

const styles = theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    width:"100%",
    // paddingTop: 10,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    // backgroundColor: "red",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
  },
  manepage: {
    minwidth: 200
  }
});

class DefaultMain extends React.Component {
  render() {
    const { classes, open} = this.props;
    // console.log(open)
    return (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >

          {/* <div className={classes.drawerHeader} /> */}
          <div className={classes.manepage}>
            <Route exact path={ROUTES.MAIN} component={MainView} />
            <Route path={ROUTES.MAIN_PAGE_LIST} component={PageListModel} />
            <Route path={ROUTES.MAIN_PAGE_LIST_DRIVE} component={DriveListModel} />
            <Route path={ROUTES.MAIN_TIMELINE} component={TimeLineModel} />
            <Route path={ROUTES.MAIN_PAGE} component={PageDetailModel} />
            <Route path={ROUTES.MAIN_SETTING} component={SettingView} />
          </div>
        </main>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DefaultMain);
