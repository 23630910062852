import * as types from '../actions/ActionTypes'

const initialState = {
  list: [],
}

export default function notes(state = initialState, action) {
  switch (action.type) {
    case types.NOTE_LIST:
      return {list: action.notes}
    default:
      return state
  }
}