import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main:"#1794b6"
    },
    secondary: {
      main:"#000000"
    },
    error: {
      main:"#ff0000"
    },
    background: {
      paper:"#ffffff",
      default:"#f5f5f5"
    },
    grey: {
      50: "#f2f2f2",
      100:"#e9e9e9",
      200:"#aaaaaa",
      300:"#666666",
      400:"#333333",
      500:"#cfcfcf",
    },
    action: {
      hover: "rgba(0, 0, 0, 0.03)",
      selected: "rgba(0, 0, 0, 0.10)"
    }
  },
  typography: {
    fontFamily: ["Noto Sans KR"].join(","),
    useNextVariants: true,
    h1: {
      fontWeight: 300,
      fontSize: "2.5rem",
      lineHeight: 1,
      letterSpacing: "-0.01562em"
    },
    h2: {
      fontWeight: 500,
      fontSize: "1.25rem",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.125rem",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    h5: {
      fontWeight: 300,
      fontSize: "4.6875rem",
    },
    h6: {
      fontWeight: 300,
      fontSize: "3.75rem",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "0.9375rem",
    },
    subtitle2: {

    },
    body1: {
      fontWeight: 400,
      fontSize: "0.8125rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.75rem",
    },
    button: {

    },
    caption: {

    },
    overline: {

    },

  },
  themeName: "defaultTheme"
});