import convertNeoInk from "./NeoInkConverter"
var firebase = null
var userid = ""
const dbrootName = "neonotes"
const notes = "notes"
const pages = "pages"


export function getUserId() {
  return userid;
}

/** init Database: Firebase and User uuid
  *
  * @param {string} userid - Firebase user uid 
  * @param {firebase} firebase - Firebase object
  *
  */
export function initDatabaseWithFirebaseAndUser(fb, uid){
  firebase = fb
  userid = uid
  console.log("init Database with Firebase", userid)
}

/** createNote
  *
  * @param {noteinfo} noteinfo - note(Int), owner(Int), section(Int), thumbnail(String), title(String)
  * @return {string} note uid
  *
  */
 export function createNote(noteinfo) {
  let noteRef = firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(notes)
    .doc();
  let note = noteinfo;
  note["noteid"] = noteRef.id;
  note["uid"] = userid;
  noteRef.set(note)
  .then( () => console.log("create note success"))
  .catch(err => console.log(err))

  return noteRef.id
}

/** setNoteInfo: Update
  *
  * @param {noteinfo} noteinfo - note(Int), owner(Int), section(Int), thumbnail(String), title(String)
  * @param {string} noteid Note uid

  * @return {string} note uid
  *
  */
 export function setNoteInfo(noteid, noteinfo) {
  firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(notes)
    .doc(noteid)
    .set(noteinfo)
    .then(() => {
      console.log("success note save");
    });
}

/** 
  *
  * @param {string} userid - Firebase user uid 
  * @param {firebase} firebase - Firebase object
  *
  * @return Note List
  *
  */
export function getNoteList() {
  return new Promise((resolve, reject) => {
    firebase
      .DB()
      .collection(dbrootName)
      .doc(userid)
      .collection(notes)
      .get()
      .then(notes => {
        let notelist = [];
        notes.forEach(doc => {
          notelist.push(doc.data());
        });
        resolve(notelist);
      })
      .catch(e => {
        console.log("error", e);
        reject(e);
      });
  });
}

/** note data
  *
  * @param {string} noteid Note uid
  * @return {Promise}
  *
  */
 export function getNote(noteid) {
  return new Promise((resolve, reject) => {
    firebase
      .DB()
      .collection(dbrootName)
      .doc(userid)
      .collection(notes)
      .doc(noteid)
      .get()
      .then(doc => {
        resolve(doc.data());
      })
      .catch(e => {
        console.log("error", e);
        reject(e);
      });
  });
}

/** Delete: Note

 * @param {string} noteid - note uid
 * 
 * @return {Promise}
 */
export function deleteNote(noteid) {
    firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .where("noteid", "==", noteid)    
    .get()
    .then(pages => {
      let deleteList = []
      pages.forEach( (doc, index) => {
        let deletedata = deletePage(userid,firebase,doc.id)
        deleteList.push(deletedata)
      });
      console.log("delete page count:", deleteList.length)
      return Promise.all(deleteList)
    })
    .then(() => console.log("page delete complete"))
    .catch(err => {
      console.log(err)
    })

  return firebase.DB().collection(dbrootName).doc(userid)
  .collection(notes).doc(noteid)
  .delete()
}

/** createPage
  *
  * @param {pageinfo} pageinfo - noteid(string), page(Int), thumbnail(base64), uid(useruid)
  * @return {string} pageuid
  */
 export function createPage(pageinfo) {
  return firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .add(pageinfo);
}

export function createPageDoc() {
  return firebase
  .DB()
  .collection(dbrootName)
  .doc(userid)
  .collection(pages)
  .doc()
}

/** setpageInfo: update
  *
  * @param {string} pageid - page uid
  * @param {pageinfo} pageinfo - noteid(string), page(Int), thumbnail(base64), uid(useruid)
  * @return {void}
  */
export function setpageInfo(pageid, pageinfo) {
  firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .doc(pageid)
    .set(pageinfo)
    .then(() => {
      console.log("success save");
    })
    .catch(e => console.log(e));
}

/** page list
  *
  * @param {string} noteid Note uid
  * @return {Promise}
  *
  */
export function getPageList(noteid) {
  return new Promise((resolve, reject) => {
    firebase
      .DB()
      .collection(dbrootName)
      .doc(userid)
      .collection(pages)
      .where("noteid", "==", noteid)
      .get()
      .then(pages => {
        let pagelist = [];
        pages.forEach(doc => {
          let pageinfo = doc.data();
          pageinfo["pageid"] = doc.id;
          pagelist.push(pageinfo);
        });
        resolve(pagelist);
      })
      .catch(e => reject(e));
  });
}

/** all page list (Not Use)
  * @return pages list

  */
export function getAllPages() {
  return new Promise((resolve, reject) => {
    firebase
      .DB()
      .collection(dbrootName)
      .doc(userid)
      .collection(pages)
      .get()
      .then(pages => {
        let pagelist = [];
        pages.forEach(doc => {
          let pageinfo = doc.data();
          pageinfo["pageid"] = doc.id;
          pagelist.push(pageinfo);
        });
        resolve(pagelist);
      })
      .catch(e => reject(e));
  });
}

var next;

/** getPagingPageFirst - timeline first pages
  * @param {string} userid - Firebase user uid 
  * @param {firebase} firebase - Firebase object
  * @param {object} date - Date
  * @return {Promise} pages list
  */
export function getPagingPageFirst(userid, firebase, date) {
  next = null
  return getPagingPage(userid, firebase, date)
}

/** getPagingPageF - timeline next
 * @param {object} date - Date optional
 * @return pages list
  */
export function getPagingPage(date) {
  let limit = 25;
  let d = new Date().getTime()
  if (date) d = date.getTime()
  return new Promise((resolve, reject) => {
    let pageRef = firebase
      .DB()
      .collection(dbrootName)
      .doc(userid)
      .collection(pages);

    let dataRef;

    if (next) {
      dataRef = next;
    } else {
      dataRef = pageRef.where("updated", "<", d).orderBy("updated", "desc").limit(limit);
    }

    dataRef
      .get()
      .then(pages => {
        var last = pages.docs[pages.docs.length - 1];
        next = pageRef
          .orderBy("updated", "desc")
          .startAfter(last)
          .limit(limit);
        let pagelist = [];
        pages.forEach(doc => {
          let pageinfo = doc.data();
          pageinfo["pageid"] = doc.id;
          pagelist.push(pageinfo);
        });
        resolve(pagelist);
      })
      .catch(e => reject(e));
  });
}

/** 
  *
  * @param {string} pageuid - page uid
  * @return {Promise} page
  *
  */
 export function getPage(pageuid) {
  return firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .doc(pageuid)
    .get();
}

/** oldest page
  *
  * 
  * @return {Promise} page
  *
  */
 export function getOldestPage() {
  return firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .orderBy("updated", "asc")
    .limit(1)
    .get();
}
export function getlatestPage() {
  return firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .orderBy("updated", "desc")
    .limit(1)
    .get();
}

/** setThumbnail: update only thumbnail
  *
  * @param {string} pageid - page uid
  * @param {string} thumbnail - (base64 string)
  *
  * @return {Promise}
  */
export function setThumbnail(pageid, thumbnail) {
  return firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .doc(pageid)
    .update({
      thumbnail: thumbnail
    })
}

 /** Delete: Page
 * @param {string} pageuid - page uid
 * 
 * @return {Promise}
 */
 export function deletePage(pageuid) {
   deleteStroke(userid, firebase, pageuid)
     .then(() => console.log("stroke delete complete in", pageuid))
     .catch(err => {
       console.log("deleteStroke error", err);
     });

   return firebase
     .DB()
     .collection(dbrootName)
     .doc(userid)
     .collection(pages)
     .doc(pageuid)
     .delete();
 }

  /** createStroke(Googledrive formate)
  *
  * @param {string} pageuid - page uid
  * @param {blob} strokeInfo - type(string), data(blob)
  *
  * @return {Promise} ()
  */
export function createStroke( pageuid, data) {

  let stroke = {
    stroke: data,
    uid: userid
  }

  return firebase
  .DB()
  .collection(dbrootName)
  .doc(userid)
  .collection(pages)
  .doc(pageuid)
  .collection("stroke")
  .doc("googledrive")
  .set(stroke)
}

export function createStrokeDoc(pageuid) {

  return firebase
  .DB()
  .collection(dbrootName)
  .doc(userid)
  .collection(pages)
  .doc(pageuid)
  .collection("stroke")
  .doc("googledrive")
}

 /** getStroke
  *
  * @param {string} pageuid - page uid
  *
  * @return {Promise} stroke list - stroke: (firestore blob) , uid: user uid
  */
export function getStroke(pageuid) {
  return firebase
  .DB()
  .collection(dbrootName)
  .doc(userid)
  .collection(pages)
  .doc(pageuid)
  .collection("stroke")
  .get();
}

 /** delete Stroke
  *
  * @param {string} pageuid - page uid
  * @param {string} strokeid - stroke uid
  *
  * @return {Promise} stroke delete
  */
 export function deleteStroke(pageuid) {
   let deleteDocs = []
   const docNames = ["googledrive"];
   docNames.forEach( name => {
     let doc = firebase.DB().collection(dbrootName).doc(userid)
     .collection(pages).doc(pageuid)
     .collection("stroke").doc(name)
      deleteDocs.push(doc.delete())
   })
  return Promise.all(deleteDocs)
 }

 /** updateTheme: 
  *
  * @param {number} themeType
  *
  * @return {void}
  */
export function updateTheme(themeType) {
  const theme = {
    themeType,
    uid: userid
  }
  return firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection("info")
    .doc("theme")
    .set(theme)
    .then(() => console.log("theme update Success", themeType))
    .catch(err => console.error(err))
}

 /** getTheme: 
  *
  * @return {Promise} themeType(Int)
  */
 export function getTheme() {
  return new Promise((resolve, reject) => {
    firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection("info")
    .doc("theme")
    .get()
    .then((doc) => {
      if(doc.exists) {
        let t = doc.data().themeType
        if (t) {
          resolve(t)
        }else {
          resolve(0)
        }
      }else{
        resolve(0)
      }
    })
    .catch(err => {
      console.error(err)
      resolve(0)
    })
  })
}

export function deleteNoteBatch(noteid) {
  let batch = firebase.DB().batch()
  let noteDoc = firebase.DB().collection(dbrootName).doc(userid)
  .collection(notes).doc(noteid)
  batch.delete(noteDoc)

  return new Promise((resolve, reject) => {
    firebase
    .DB()
    .collection(dbrootName)
    .doc(userid)
    .collection(pages)
    .where("noteid", "==", noteid)    
    .get()
    .then(pages => {
      pages.forEach( (doc, index) => {
        console.log(doc)
        batch.delete(doc.ref)
        let stroke = doc.ref.collection("stroke").doc("googledrive")
        batch.delete(stroke)
      });
      return batch.commit()
    })
    .then(() => {
      console.log("note, page and stroke delete complete", noteid)
      resolve()
    })
    .catch(err => {
      console.log(err)
      reject()
    })
  })
}


  /** createSharePage(Googledrive formate)
  *
  * @param {string} pageuid - page uid
  * @param {blob} strokeInfo - type(string), data(blob)
  *
  * @return {Promise} ()
  */
 export function sharePage( page, stroke) {
  
  return new Promise((resolve, reject) => {
    let sharepageRef = firebase
    .DB()
    .collection("sharestudio")
    .doc()
    let docid = sharepageRef.id
  
    let shareInfo = {
      email: page.email,
      uid: page.uid,
      updated: page.updated,
      type:"page"
    }
  
    let inkdata = convertNeoInk(page, stroke)
    let pageName = getNeoinkName(page)
    let storageRef = firebase.Storage().child("sharestudio/"+ docid + "/" + pageName)
    let meta = {contentType: "application/json"}
    storageRef
    .put(inkdata, meta)
    .then(()=> sharepageRef.set(shareInfo))
    .then( () => {
      console.log("create share page success")
      resolve(docid)
    })
    .catch(err => {
      console.log("error", err)
      reject()
    })
  })
}

function getNeoinkName(page){
  console.log("page structure", page)
  let s = 3
  let o = 27
  let b = page.note
  let p = page.page
  let pageName = s + "_" + o + "_" + b +"_" + p + ".neoink"
  return pageName
}