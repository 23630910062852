import * as types from '../actions/ActionTypes'

const initialState = {
  currentNote: null,
}

export default function note(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_NOTE:
      return {currentNote: action.note}
    default:
      return state
  }
}