import React from "react";
import PropTypes from "prop-types";

import { Typography, List, ListItem, ListSubheader, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as Icons from "@material-ui/icons";

const propTypes = {
  month: PropTypes.array,
  width: PropTypes.number,
  selectMonth: PropTypes.func
};

const defaultProps = {};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  baseStyle: {
    backgroundColor: theme.palette.background.paper,
    cell: {
      height: 75
    }
  },
  select: {
    "& p": {
      color: theme.palette.primary.main
    }
  },
  month: {
    fontWeight: 400,
    fontSize: 20,
    width: "100%",
    color: theme.palette.grey[300]
  },
  name: {
    fontSize: 13,
    color: theme.palette.grey[200],
    width: "100%"
  },
  bg: {
    backgroundColor: theme.palette.background.paper
  }
});

class TimeLineMenuView extends React.Component {
  constructor(props) {
    super(props);
    const d = new Date();
    const v = d.getFullYear() + "." + (d.getMonth() + 1);
    this.state = {
      selected: v
    };
  }

  selectMonth = e => {
    // console.log(e);
    this.setState({ selected: e });
    this.props.selectMonth(e);
  };

  render() {
    const { classes, month, width } = this.props;
    const h = window.innerHeight;
    const scrollOuter = {
      top: 0,
      width: width,
      height: h,
      position: "absolute",
      overflow: "hidden",
      // backgroundColor: "white",
      zIndex: 900
    };

    const scrollInner = {
      width: width + 8,
      height: h - 50,
      overflowY: "scroll",
      overflowX: "none"
    };
    return (
      <div className={classes.root} style={scrollOuter}>
        <Icons.CalendarToday style={{ height: 50, color: this.props.open ? "#e9e9e9" : "#ffffffff", margin: 0 }} />
        {/* <Divider /> */}
        <div className={classes.bg} style={scrollInner}>
          <List>
            {month.map(m => {
              if (m.type === "year") {
                return (
                  <ListSubheader key={m.value} className={classes.bg} style={{ height: 90}}>
                    <Divider />
                    <Typography align="center" style={{ paddingTop: 33, color: "#aaa", fontSize: 16, fontWeight: 500 }}>
                      {m.name}
                    </Typography>
                  </ListSubheader>
                );
              }
              let selected = this.state.selected === m.value;

              return (
                <ListItem
                  className={selected ? classes.select : classes.baseStyle}
                  alignItems={"center"}
                  key={m.value}
                  // divider={true}
                  button={m.type === "month"}
                  onClick={() => this.selectMonth(m.value)}
                  selected={selected}
                >
                  <div style={{ width: "100%" }}>
                    <Typography className={classes.month} variant="body1" align="center">
                      {m.mon}
                    </Typography>
                    <Typography className={classes.name} variant="body1" align="center">
                      {m.name}
                    </Typography>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    );
  }
}

TimeLineMenuView.propTypes = propTypes;
TimeLineMenuView.defaultProps = defaultProps;

export default withStyles(styles)(TimeLineMenuView);
