import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";
import NoteView from "./NoteView";

const propTypes = {
  expand: PropTypes.bool,
  notes: PropTypes.array,
  user: PropTypes.object,
  currentnote: PropTypes.object,
  goTimeLineView: PropTypes.func,
  selectNote: PropTypes.func,
  deleteNote: PropTypes.func
};

const defaultProps = {};

const styles = theme => ({
  sideroot: {
    backgroundColor: theme.palette.background.default,
  },
  titleouter: {
    display: 'table',
    height: 60,
    paddingLeft: 20,
  },
  titleinner: {
    fontWeight:'bold',
    display:'table-cell',
    verticalAlign:"middle"
  },
  notelist: {
    padding: 0,
  },
});

class NoteListView extends React.Component {
  render() {
    let { notes, classes } = this.props;

    return (
      <div className={classes.sideroot} style={this.props.expand ? {} : {display: 'none'}}>
        <List className={classes.notelist} >
          {notes.map(note => (
            <NoteView
              note={note}
              key={note.noteid}
              selectNote={this.props.selectNote}
              deleteNote = {this.props.deleteNote}
              selected={
                this.props.currentnote
                  ? this.props.currentnote.noteid === note.noteid
                  : false
              }
            />
          ))}
        </List>
      </div>
    );
  }
}

NoteListView.propTypes = propTypes;
NoteListView.defaultProps = defaultProps;

export default withStyles(styles)(NoteListView);
