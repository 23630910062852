import React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import * as Icons from "@material-ui/icons"
import {
  CardMedia,
  ListItem,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box
} from "@material-ui/core";
import { MetaData } from "../../Utils";


const styles = theme => ({
  noteitem: {
    height: 126,
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    '&:focus': {
      '& svg': {
        color: theme.palette.primary.main,
      },
      '& p': {
        color: theme.palette.primary.main,
      },
      '& h4': {
        color: theme.palette.primary.main,
      },
    }
  },
  noteSelected: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& p': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
    },
  },
  img: {
    width: 64,
    height: 96,
  },
  title: {
    marginTop: 19
  },
  body1: {
    marginTop: 20,
    color: theme.palette.grey[200]
  }

});

const propTypes = {
  classes: PropTypes.object.isRequired,
  note: PropTypes.object,
  selectNote: PropTypes.func,
  deleteNote: PropTypes.func,
  selected: PropTypes.bool
};


class NoteView extends React.Component {
  state = {
    open: false,
    total: 0
  }


  deleteClose = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  deleteOpen = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  }

  deleteNote = (e) => {
    e.stopPropagation();
    this.setState({ open: false });
    this.props.deleteNote(this.props.note)
  }

  render() {
    const { classes, note, selected, selectNote} = this.props;
    // console.log(note)
    const isFirebase = (note.notetype === "firebase" )//Download Note Type
    let titleStyle = {color: "#aaa"}
    if (isFirebase) titleStyle = {}

    return (
      <ListItem
        button
        onClick={() => selectNote(note)}
        className={clsx(classes.noteitem, {
          [classes.noteSelected]: selected
        })}
        divider={false}
        selected={selected}
      >
        <Box width={114}>
          <CardMedia
            className={classes.img}
            image={
              note.thumbnail ? note.thumbnail : "../assets/cover/default.png"
            }
            title="Note Cover"
            src=""
          />
        </Box>

        <Box width={1} display="flex" flexDirection="column">

            <Typography variant="h4" className={classes.title} style={titleStyle}>
              {note.title}
            </Typography>

          <Box width={1} display="flex">
            <Box width="100%">
              {note.usingPages ? (
                <Typography variant="body1" className={classes.body1}>
                  {note.usingPages}  { this.state.total !== 0 && "/"+ this.state.total}
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.body1} style={{color: "#aaa"}}>
                  {this.formatedSize(note.size)}
                  {/* {" (Version: "} {note.version} {")"} */}
                </Typography>
              )}
            </Box>

            {isFirebase && (
              <Box flexShrink={0}> 
                <IconButton
                  onClick={this.deleteOpen}
                  style={{marginTop:20}}
                >
                  <Icons.DeleteOutline />
                </IconButton>
              </Box>
            )}
            </Box>

          </Box>

        <Dialog
          open={this.state.open}
          onClose={this.deleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: 360 }}
        >
          <DialogTitle  id="alert-dialog-title">
            <Typography variant="h4">
              {"Delete this notebook?"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This note will be deleted from your data. You can get it back
              via Google Drive.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteNote} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </ListItem>
    );
  }
  componentDidMount() {
    if (this.props.note.notetype === 'firebase') {
      MetaData.getNoteInfo(this.props.note).then(note => {
        if (note) {
          this.setState({total: note.totalcount})
        }
      })
    }
  }

  formatedDate = (time) => {
    let date = new Date(time)
    const months = ['jan','feb','mar','apr','may','jun',  'jul','aug','sep','oct','nov','dec']
    let day = date.getDay()
    let month = date.getMonth()
    let year = date.getFullYear()
    let formated = "" + day + " " + months[month].toUpperCase() + " " + year
    return formated
  }
  
  formatedSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
}

NoteView.propTypes = propTypes


export default withStyles(styles)(NoteView);
