import React, { Fragment } from "react";
import { Database, DateHelper } from "../Utils";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import * as ROUTES from "../../routes";
import { withRouter } from "react-router-dom";
import TimeLineMenuView from "./TimeLineMenuView";
import TimeLinePageView from "./TimeLinePageView";

import { Grid, Box } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

class TimeLineModel extends React.Component {
  constructor(props) {
    super(props);
    let st = new Date();
    st.setMonth(0);
    // let m = this.getMonthData(st, new Date());
    this.state = {
      days: {},
      month: []
    };
    this.nextLoding = true;
    this.props.selectNote(null);

  }

  selectMonth = value => {
    console.log("Timeline select", value)
    let d = new Date(value);
    d.setMonth(d.getMonth() + 1);
    console.log("page from", d);
    let user = this.props.user;
    if (user) {
      this.setState({ days: {} });
      this.updatePageList(d);
    }
  };

  selectPage = page => {
    Database.getNote(page.noteid).then(note => {
      this.props.selectNote(note);
      this.props.selectPage(page);
      this.props.handleDrawer(false);
      this.props.history.push(ROUTES.MAIN_PAGE);
    });
  };

  nextPage = () => {
    if (this.nextLoding) {
      this.nextLoding = false;
      Database.getPagingPage()
        .then(pages => {
          this.setDays(pages);
        })
        .catch(e => console.log("error", e));
    }
  };

  handleDrawer = () => {
    this.props.handleDrawer(!this.props.open);
  };

  render() {
    const { days, month } = this.state;
    const { open } = this.props;
    let h = window.innerHeight; // - 72
    let monthWidth = 70;
    let withdown = isWidthDown("sm", this.props.width);
    if (withdown) {
      monthWidth = 0;
    }
    return (
      <Fragment>
        <Box display={{ xs: "none", sm: "block" }}>
          <Grid
            container
            spacing={5}
            // style={{ width: "100%", height: h, overflowY: "hidden", margin: 0, minWidth: 300}}
            style={{ width: "100%", height: h, overflowY: "scroll", margin: 0, minWidth: 300 }}
          >
            {/* year and month menu */}
            <Grid item style={{ height: h, width: monthWidth, padding: 0, position: "relative" }}>
              <TimeLineMenuView selectMonth={this.selectMonth} month={month} width={monthWidth} open={open} />
            </Grid>
            {/* pages per days */}
            <Grid item xs={12} sm container style={{ height: h, padding: 0 }}>
              <TimeLinePageView
                days={days}
                selectPage={this.selectPage}
                nextPage={this.nextPage}
                userName={this.props.user.displayName}
              />
            </Grid>
          </Grid>
        </Box>
        {/* Mobile */}
        <Box display={{ xs: "block", sm: "none" }}>
          <TimeLinePageView
            days={days}
            selectPage={this.selectPage}
            nextPage={this.nextPage}
            userName={this.props.user.displayName}
            isMobile={true}
          />
        </Box>
      </Fragment>
    );
  }

  componentDidMount() {
    this.mounted = true;
    this.getMonthDataUpdate();
    this.updatePageList(new Date());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updatePageList = date => {
    console.log("updatePageList Start", date);
    Database.getPagingPageFirst(date)
      .then(pages => {
        this.setDays(pages);
      })
      .catch(e => {
        this.setState({ days: null });
        console.log("error", e);
      });
  };

  setDays = pages => {
    console.log("setDays data, count page:", pages.length);
    if (pages.length < 1) {
      return;
    }
    let days = this.state.days;
    pages.forEach(p => {
      let d = new Date(p.updated);
      // console.log("page date", d)
      let key = d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate();
      if (days[key]) {
        days[key].push(p);
      } else {
        days[key] = [];
        days[key].push(p);
      }
    });
    console.log("set days", Object.keys(days));
    this.nextLoding = true;
    if (this.mounted) this.setState({ days: days });
  };

  getMonthDataUpdate = () => {
    Promise.all([Database.getOldestPage(), Database.getlatestPage()])
      .then(p => {
        let oldDate = new Date(p[0].docs[0].data().updated);
        let lastDate = new Date(p[1].docs[0].data().updated);
        let m = this.getMonthData(oldDate, lastDate);
        if (this.mounted) this.setState({ month: m });
      })
      .catch(e => console.log("oldest page upate error", e));
  };

  getMonthData = (startDate, endDate) => {
    const month = [];
    let d = endDate;
    d.setDate(28);
    startDate.setDate(1);
    let y = {
      type: "year",
      name: d.getFullYear(),
      value: d.getFullYear() + ".99"
    };
    if (d.getMonth() !== 11) {
      month.push(y);
    }
    while (d > startDate) {
      if (d.getMonth() === 11) {
        let y = {
          type: "year",
          value: d.getFullYear() + ".99",
          name: d.getFullYear()
        };
        month.push(y);
      }
      let m = {
        type: "month",
        value: d.toString(),
        mon: d.getMonth() + 1,
        name: DateHelper.formatedMonth(d)
      };
      month.push(m);
      d.setMonth(d.getMonth() - 1);
    }
    return month;
  };
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    open: state.drawer.open
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPage: page => dispatch(Actions.changePage(page)),
    selectNote: note => dispatch(Actions.changeNote(note)),
    handleDrawer: open => dispatch(Actions.drawer(open))
  };
};

const withRouterTimeLineModel = withWidth()(withRouter(TimeLineModel));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterTimeLineModel);
