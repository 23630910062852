export default function convertNeoInk(page, stroke) {
  let p = {
    version: 1,
    section: 3,
    owner: 27,
    bookCode: page.note,
    pageNumber: page.page,
    created: page.updated,
    updated: page.updated,
    userName: page.userName,
    photoURL: page.photoURL
  }
  let strokes = []
  stroke.forEach( item => {
    let st = {
      penTipType: item.penTipType,
      color: byteArrayToInt(item.penTipColor),
      thickness: thicknessToNCode(item.penThickness),
      startTime: item.time,
      dotCount: item.dotCount,
      data: gdStrokeBlobtoDotBlop(item.dotData, item.dotCount)
    }
    strokes.push(st)
  });
  p.strokes = strokes
  console.log("neo ink page data", p)
  let jsonString = JSON.stringify(p)
  var blob = new Blob([jsonString], {type: "application/json"});
  return blob
}

function thicknessToNCode(index) {
  let widthArray = [0.1, 0.2, 0.5, 0.9, 1.7]
  return widthArray[index]
}

export function byteArrayToInt(bytes) {
  let arr = new Uint8Array(bytes)
  let dv = new DataView(arr.buffer)
  return dv.getInt32(0, true)
}

export function gdStrokeBlobtoDotBlop(gdblob, count) {
  let dotBlob = gdblob;
  let b = []
  for (let i = 0; i < count; i++) {
    let st = i * 13;
    let end = st + 13;
    let d = dotBlob.slice(st, end);
    let delta = d[12]
    let f = d.slice(8,12)
    let x = d.slice(0,4)
    let y = d.slice(4,8)
    let tr = [90,90,90]
    b.push(delta)
    f.forEach(d => b.push(d))
    x.forEach(d => b.push(d))
    y.forEach(d => b.push(d))
    tr.forEach(d => b.push(d))
  }
  // console.log("Data size", count, " x 16 = ", count*16, b.length)
  // let result = new Blob([new Uint8Array(b)])
  var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(b)));

  return base64String;
}