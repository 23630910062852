import * as types from '../actions/ActionTypes'

const initialState = {
  list: [],
}

export default function pages(state = initialState, action) {
  switch (action.type) {
    case types.PAGE_LIST:
      return {list: action.pages}
    default:
      return state
  }
}