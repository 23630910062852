const KeyEvent = "event"
const KeyCategory = "event_category"
const KeyLabel = "event_label"
const KeyValue = "value"

const category_googledrive = "GoogleDrive"

export function send(event, category, label, value){
  const tag = {
    [KeyCategory]: category,
    [KeyLabel]: label,
    [KeyValue]: value
  }
  window.gtag(KeyEvent, event, tag)
}

export function themeChange(type) {
  let themelabel = "dark"

  if (type === 0) {
    themelabel = "default"
  }else if (type === 1) {

  }
  send("change", "Theme", themelabel, type)
}

export function googleDriveImport(notetype, pagecount){
  send("import", category_googledrive,notetype, pagecount)
}

export function googleDriveDelete(notetype){
  send("delete", category_googledrive,notetype, 1)
}

export function homelink(type) {
  const cate = "Home"
  if (type === 0){
    send("window", cate, "window", 1)
  } else if (type === 1) {
    send("googleplay", cate, "android", 1)
  }else if (type === 2) {
    send("appstore", cate, "ios", 1)
  }
}

export function pagedetail(action, lable="", value=1){
  send(action, "PageDetail", lable, value)
}