import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Dialog, IconButton, Stepper, Step, StepLabel, StepContent, StepIcon } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const useStyles = makeStyles(theme => ({
  root: {
    displey: "flex",
    flexDirection: "column",
    width: "100%"
    // backgroundColor: theme.palette.background.paper
  }
}));

function getSteps() {
  return [
    "Import Notebooks from Google Drives",
    "Extract Notebooks",
    "Create a page for the Web",
    "Uploading Web server",
    "Completion"
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Import Notebook";
    // case 1:
    //   return "Extract Notebooks";
    // case 2:
    //   return "Create a page for the Web";
    // case 3:
    //   return "Uploading Web server";
    // case 4:
    //   return "Completion";
    default:
      return "";
  }
}

function DriveDownloadView({ activeStep, error, open, handleEntering, handleClose, cancel, dialogWidth, fullScreen, progress }) {
  const classes = useStyles();

  const steps = getSteps();

  return (
    <Dialog
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
    >
      <div className={classes.root} style={{ width: fullScreen ? "100%" : dialogWidth }}>
        <div style={{ display: "flex" }}>
          <IconButton aria-label="close" onClick={cancel} style={{ marginLeft: "auto", marginRight: 0 }}>
            <Icons.Close />
          </IconButton>
        </div>

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
            <Step key={label}>
              {index === error && <StepIcon error={true} /> }
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}
                  {index === 2 && progress.page !== 0 && progress.page + "/" + progress.total }
                </Typography>
              </StepContent>
            </Step>
            )
          })}
        </Stepper>
      </div>
    </Dialog>
  );
}

export default withMobileDialog()(DriveDownloadView);
