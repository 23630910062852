import * as types from '../actions/ActionTypes'


const inintValue = {
  user: null
}

export default function login(state = inintValue, action) {
  if (action.type === types.LOGIN) {
    return {
      user: action.user
    }
  } 
  return state
}
