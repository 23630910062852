import React from "react";
import PropTypes from "prop-types";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import {DateHelper} from "../Utils"
import {withStyles} from "@material-ui/core/styles"
import {
  Grid,
  Typography,
  GridList,
  GridListTile,
  Divider
} from "@material-ui/core";
import PageView from "../PageList/PageView";

const propTypes = {
  pages: PropTypes.array,
  selectPage: PropTypes.func
};

const defaultProps = {};

const styles= (theme) => ({
  cell: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    overflowX: "hidden",
    width: "100%"
  },
  date: {
    color: theme.palette.grey[300],
    fontWeight: 300,
    lineHeight: 1.5
  },

});

class TimeLinePageViewDay extends React.Component {


  render() {
    const { classes, pages,selectPage } = this.props;
    let withdown = isWidthDown("sm", this.props.width);
    let style ={}
    if (withdown) {
      style = { justifyContent: "center", margin: 0 }
    }
    let date = new Date(pages[0].updated)
    let Line1 = date.getDate() + " " +DateHelper.formatedMonth(date)
    let Line2 = date.getFullYear() 
    return (
      <React.Fragment>
        <Grid container spacing={2}>
        {!withdown &&
          (<Grid item style={{ width: 120}} >
            <Typography className={classes.date} variant="subtitle1">{Line1}</Typography>
            <Typography className={classes.date} variant="subtitle1">{Line2}</Typography>
            <Divider style={{width: 1, height: 'calc(100% - 130px)', margin: '0 auto', backgroundColor: '#aaa', marginTop: 30}}></Divider>
          </Grid>)
          
        }
          <Grid item xs={12} sm container>
            <GridList cellHeight={180} className={classes.cell} style={style}>
              <GridListTile key="Subheader" cols={2} style={{ height: "auto" }} >
              {withdown &&
                <Typography variant="subtitle1" className={classes.date} style={{fontWeight: 700}}>{Line1 + Line2}</Typography>
              }
              </GridListTile>
              {pages.map((page, index) => (
                <PageView key={index} page={page} selectPage={selectPage} timeLine={true}></PageView>
              ))}
            </GridList>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

TimeLinePageViewDay.propTypes = propTypes;
TimeLinePageViewDay.defaultProps = defaultProps;


export default withStyles(styles)(withWidth()(TimeLinePageViewDay))