import React, { Fragment } from "react";
import JSZip from "jszip";

import * as ROUTES from "../../../routes";
import { withRouter } from "react-router-dom";

import { GDApi, Database, MetaData, Tag } from "../../Utils";
import { withFirebase } from "../../../Firebase";

import { connect } from "react-redux";
import * as Actions from "../../../store/actions";

import GoogleDriveDataStruct from "../../../Data/GoogleDriveDataStruct";
import FabricRender from "../../PageDetail/FabricRender";

import DriveListView from "./DriveListView";

import { Typography } from "@material-ui/core";

class DriveListModel extends React.Component {
  state = {
    pages: [],
    activeStep: 0, // 0: Download, 1: Extract , 2: Create page, 3: upload, 4: complete
    error: -1,
    note: null,
    cancel: false,
    size: { w: 178, h: 254 },
    progress: {
      total: 0,
      page: 0
    }
  };


  handelCancel = () => {
    this.setState({ cancel: true });
  };

  render() {
    if (!this.props.note) {
      return (
        <Fragment>
          <Typography variant="h1">Google Drive Data Migration</Typography>
        </Fragment>
      );
    }
    let { pages, error, progress } = this.state;

    return (
      <DriveListView
        pages={pages}
        progress={progress}
        error={error}
        title={this.props.note.title}
        activeStep={this.state.activeStep}
        size={this.state.size}
        migrationNote={this.migrationNote}
        handelCancel={this.handelCancel}
      />
    );
  }

  checkCancel = () => {
    if (this.state.cancel) {
      this.setState({activeStep: 0})
      throw new Error("cancel");
    }
  };
  //Step1. Migration Start
  migrationNote = () => {
    this.setState({ cancel: false });
    const { note } = this.props;
    console.log("note info", note);
    if (note) {
      console.log("google drive id", note.gdid);
      this.downloadNote(note.gdid);
    } else {
      alert("선택한 노트가 없습니다.");
    }
  };

  //Step2. Download Note file from GoogleDrive
  downloadNote = id => {
    let firebase = this.props.firebase;
    let userid = firebase.getUser().uid;
    let pages = [];
    let noteInfo = {
      owner: 27,
      section: 3
    };
    let noteCoverZip;
    GDApi.download(id)
      .then(res => {
        this.checkCancel();
        console.log(res.body.length);
        var zip = new JSZip();
        return zip.loadAsync(res.body);
      })
      .then(zip => {
        // console.log(unzip)
        this.setState({ activeStep: 1 });
        this.checkCancel();
        let dic = zip.files;
        let noteInfoXml = dic["NoteInfo.xml"];
        if (!noteInfoXml) noteInfoXml = dic["/NoteInfo.xml"];
        Object.keys(dic).forEach((key, index) => {
          if (key.includes(".page_store/page.data")) {
            pages.push(dic[key]);
          } else if (key.includes(".jpg") || key.includes(".png")) {
            console.log("have a cover image .zip", dic[key]);
            noteCoverZip = dic[key]
          }
        });
        console.log("update page", pages.length);
        return noteInfoXml.async("uint8array");
      })
      .then(note => {
        this.checkCancel();

        // note 처리하고~~
        let string = new TextDecoder("utf-8").decode(note);
        let parser = new DOMParser();
        let noteXml = parser.parseFromString(string, "text/xml");
        let notebookId = parseInt(noteXml.getElementsByTagName("notebookId")[0].childNodes[0].nodeValue);
        let notebookTitle = noteXml.getElementsByTagName("notebookTitle")[0].childNodes[0].nodeValue;
        let createdDate = parseInt(noteXml.getElementsByTagName("createdDate")[0].childNodes[0].nodeValue);
        let lastModifiedDate = parseInt(noteXml.getElementsByTagName("lastModifiedDate")[0].childNodes[0].nodeValue);
        let totNoPages = parseInt(noteXml.getElementsByTagName("totNoPages")[0].childNodes[0].nodeValue);

        console.log(
          "Note Info",
          notebookId,
          notebookTitle,
          createdDate,
          lastModifiedDate,
          totNoPages,
        );

        noteInfo.note = notebookId;

        // Note Section Setting
        const section0Note = [605, 606, 608, 621, 622, 626, 627]
        if (section0Note.includes(noteInfo.note)){
          console.log("note info section 0", noteInfo)
          noteInfo.section = 0
        }
        noteInfo.title = notebookTitle;
        noteInfo.updated = new Date(lastModifiedDate).getTime();
        if (notebookId === 0) {
          let digitalNoteType = parseInt(noteXml.getElementsByTagName("digitalNoteType")[0].childNodes[0].nodeValue);

          if (digitalNoteType === 0 && noteCoverZip) {
            return this.customNoteCover(noteCoverZip)
          } else {
            noteInfo.thumbnail = "../../assets/cover/icon_coverlist_d" + ("0" + digitalNoteType).slice(-2) + ".png";
          }
        }
        return MetaData.getNoteInfo(noteInfo);
      })
      .then(noteMeta => {
        this.setState({  activeStep: 2 });

        this.checkCancel();
        noteInfo.usingPages = pages.length;
        noteInfo.gdid = this.props.note.gdid;
        noteInfo.notetype = "firebase";
        console.log("create note", noteInfo);
        Tag.googleDriveImport(noteInfo.note, pages.length)
        noteInfo.totalcount = 0
        if (noteMeta) {
          noteInfo.thumbnail = noteMeta.thumbnail;
          if (noteMeta.totalcount) {
            noteInfo.totalcount = noteMeta.totalcount
          }
        } else {
          console.log("digital note, not custom(1~20)");
        }
        //Create Note
        let noteid = Database.createNote(noteInfo);
        // Notelist update
        noteInfo.noteid = noteid;
        let updateNotes = this.props.notes.map(n => {
          if (n.gdid === noteInfo.gdid) {
            return noteInfo;
          } else {
            return n;
          }
        });
        console.log("note update", updateNotes);
        this.props.onNotes(updateNotes);
        this.setState({ note: noteInfo });

        // Page Thumnail and Save to Firebase
        this.pageThumbnailandSave(noteInfo, userid, pages, firebase);
      })
      .catch(err => {
        if (err === "cancel") {
          console.log("cancel google drive import");
        } else {
          console.log(err);
          this.setState({error: 1})
        }
      });
  };

  // Step 3 Make Thumnail with page Data
  /*
   * @param
   */
  pageThumbnailandSave = (noteInfo, userid, pages, firebase) => {
    let batch = firebase.DB().batch();

    let totalPage = pages.length;
    let successPage = 0;
    // let createPage = 0;
    // page 처리한다~
    pages.forEach((page, index) => {
      // if(index > 1) return // 일단  두페이지만 처리한다....
      let pageNum = parseInt(
        page.name
          .split(".")[0]
          .split("/")
          .pop()
      );
      let firebasepage = {
        noteid: noteInfo.noteid,
        notetitle: noteInfo.title,
        page: pageNum,
        thumbnail: "",
        uid: userid
      };

      console.log(firebasepage);

      // Thumnail Start
      page
        .async("uint8array")
        .then(u8 => {
          this.checkCancel();
          let gd_data = new GoogleDriveDataStruct(u8);
          let strokeBlob = firebase.db.app.firebase_.firestore.Blob.fromUint8Array(u8);
          console.log(gd_data);
          firebasepage.updated = new Date(gd_data.lastModifiedDate).getTime();
          firebasepage.note = gd_data.noteType;

          // Drawing Page Data
          let drawingPageInfo = {
            section: noteInfo.section,
            owner: noteInfo.owner,
            note: gd_data.noteType,
            page: pageNum
          };

          // Canvase Element Set
          let currentpages = this.state.pages;
          currentpages.push(drawingPageInfo);
          this.setState({ pages: currentpages });

          // Page Size and Bgurl set
          let rectProm = MetaData.getNoteSize(drawingPageInfo);
          let bgurlProm = MetaData.getBackgroudImage(drawingPageInfo, firebase);
          Promise.all([rectProm, bgurlProm])
            .then(arr => {
              this.checkCancel();

              let bgurl = "";
              let rect = {};
              arr.forEach(d => {
                if (typeof d === "string") {
                  bgurl = d;
                } else {
                  rect = d;
                }
              });
              return this.drawStroke(bgurl, rect, gd_data.strokeList, Date.now());
            })
            .then(thumbnail => {
              firebasepage.thumbnail = thumbnail;
              let pageRef = Database.createPageDoc();
              batch.set(pageRef, firebasepage);

              let stroke = {
                stroke: strokeBlob,
                uid: Database.getUserId()
              };
              let strokeRef = Database.createStrokeDoc(pageRef.id);
              batch.set(strokeRef, stroke);
              console.log("page drawing success");
              successPage += 1;
              this.progress(totalPage, successPage);
              if (totalPage === successPage) {
                console.log("save data start: last", successPage);
                batch
                  .commit()
                  .then(() => {
                    console.log("save data success: last")
                    this.completeTask()
                  })
                  .catch(err => {
                    console.log("save last data error", err)
                    this.setState({error: 3})
                  });
              } else if (successPage % 10 === 0) {
                console.log("save data page", successPage);
                batch
                  .commit()
                  .then(() => console.log("save data success", successPage))
                  .catch(err => {
                    console.log("save data error", successPage, err)
                    this.setState({error: 3})
                  });
                batch = firebase.DB().batch();
              }
            })
            .catch(err => console.log("Drawing Page", err));
        })
        .catch(err => console.log("Note error", err));
    });
  };

  //Step 3 - 1 Draw stroke
  drawStroke = (bgurl, rect, stroke, id) => {
    const size = {
      w: this.state.size.w,
      h: this.state.size.w * rect.height / rect.width
    }

    return new Promise((resolve, reject) => {
      console.log("Draw Stroke", stroke.length, rect, size, id);
      console.log(bgurl);
      let renderer = new FabricRender("" + id);
      renderer.setCanvas(size, bgurl).then(() => {
        console.log("drawing success");
        let dataurl = renderer.canvas.getElement().toDataURL();
        resolve(dataurl);
      });
      renderer.drawingStrokeThumbnail(stroke, rect, size);
    });
  };

  // Custom Note Cover
  customNoteCover = (zip) => {
    return new Promise((resolve, reject) => {
      zip.async("uint8array").then(data => {
        const blob = new Blob([data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        console.log("note image origin blob", blob);
        const w = 80;
        const h = 120;
        let img = new Image();
        img.src = url;
        img.onload = () => {
          console.log("note image size: " + img.width, img.height);
  
          const elem = document.createElement("canvas");
          elem.width = w;
          elem.height = h;
          const ctx = elem.getContext("2d");
          const dx = img.width / 2 - (img.height * 2) / 6
          const dw = (img.height * 2) / 3
          ctx.drawImage(img, dx, 0, dw, img.height, 0, 0, w, h);
          console.log("note image final size", img.width, img.height);
          const customThumbnailImage = ctx.canvas.toDataURL();
          const result = {
            thumbnail: customThumbnailImage
          }
          resolve(result)
        };
      });
    })
  }

  progress = (total, page) => {
    console.log("total", page, "/", total)
    this.setState({
      progress: {
        total: total,
        page: page
      }
    })

    if (total === page) {
      console.log("activeStep: 3, uploading Web Server Start")
      this.setState({  activeStep: 3 });

    } 
  };

  completeTask= () => {
    console.log("activeStep: 4, complete")

    this.setState({ activeStep: 4 });
      // 1초후 페이지 닫고 이동
      setTimeout(() => {
        console.log("activeStep: 5, close and page move")
        this.setState({  activeStep: 5 });
        this.props.selectNote(this.state.note);
        this.props.history.push(ROUTES.MAIN_PAGE_LIST);
      }, 1000);
  }

  errorTask = (err) => {
    console.log("error", err)
  }
}
const mapStateToProps = state => {
  return {
    notes: state.notes.list,
    pages: state.pages.list,
    note: state.note.currentNote
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNotes: notes => dispatch(Actions.noteList(notes)),
    selectNote: note => dispatch(Actions.changeNote(note))
  };
};

const withDriveListModel = withRouter(withFirebase(DriveListModel));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDriveListModel);
