import React from "react";
import * as Icon from "@material-ui/icons";
import ModalPageList from "./ModalPageList";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Grid, Typography, Box } from "@material-ui/core";
import {Tag} from '../Utils'

const styles = theme => ({
  top: {
    // flexGrow: 1,
    width: "100%",
    height: 60,
    backgroundColor: theme.palette.background.paper, //default or paper
    zIndex: 1100
  },

  left: {
    display: "flex",
    // justifyContent: "left",
    alignItems: "center",

    // height: '100%',
    backgroundColor: theme.palette.background.paper //default or paper
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper //default or paper
  }
});

class PageTopView extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    Tag.pagedetail("listview")
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  goBack = () => {
    this.props.goBack();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.top}>
        <Grid container spacing={0} style={{ height: 60 }}>
          <Grid item xs={6} className={classes.left}>
            <IconButton onClick={this.goBack} style={{ marginLeft: 30 }}>
              <Icon.KeyboardBackspace />
            </IconButton>
            <Box display={{ xs: "none", md: "block" }}>
              <Typography variant="h4" style={{ marginLeft: 10 }}>
                {this.props.title + " / "}
              </Typography>
            </Box>
            <Typography variant="h4" color="primary" style={{ marginLeft: 5 }}>
              {this.props.page + " Page"}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.right}>
            {/* Share */}
            <IconButton onClick={this.props.sharePage}>
              <Icon.ShareOutlined />
            </IconButton>
            <IconButton aria-label="ZoomWidth" onClick={this.props.saveHandle}>
              <Icon.SaveAltSharp />
            </IconButton>
            <IconButton onClick={this.handleClickOpen} style={{ marginRight: 30 }}>
              <Icon.AppsOutlined />
            </IconButton>
          </Grid>
        </Grid>

        <ModalPageList open={this.state.open} onClose={this.handleClose} title={this.props.title} />
      </div>
    );
  }
}

export default withStyles(styles)(PageTopView);
