import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as ROUTES from "../../routes";
//React Class
import NoteListModel from "./NoteList/NoteListModel";
//UI elemnet
import * as Icons from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, Typography, Divider, ListItem } from "@material-ui/core";

//Menu item
import ListItemIcon from "@material-ui/core/ListItemIcon";

//SVG
import * as NIcon from "../icon";

const studioLogo = "../../assets/img/studioLogo.svg";
const studioLogoDark = "../../assets/img/studioLogo1.svg";

const propTypes = {
  user: PropTypes.object,
  firebase: PropTypes.object,
  handleRouting: PropTypes.func,
  search: PropTypes.func
};

const defaultProps = {};

const styles = theme => ({
  sidemenu: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column"
  },
  menu: {
    // minHeight: 60,
    height: 60,
    "&:focus": {
      "& svg": {
        color: theme.palette.primary.main
      },
      "& h3": {
        color: theme.palette.primary.main
      }
    },
    "& svg": {
      color: theme.palette.grey[500]
    },
    "& h3": {
      color: theme.palette.grey[300]
    }
  },
  menuSelected: {
    "& svg": {
      color: theme.palette.primary.main
    },
    "& h3": {
      color: theme.palette.primary.main
    }
  },
  menuContact: {
    height: 60,
    "& svg": {
      color: theme.palette.grey[500]
    },
    "& h3": {
      color: theme.palette.grey[300]
    }
  },
  icon: {
    padding: 10
  },
  title: {
    marginLeft: -10
  },
  divider: {
    backgroundColor: theme.palette.grey[50]
  },
  space: {
    flex: 1
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    marginTop: 80,
    marginBottom: 20
  }
});

class SideMenu extends React.Component {
  state = {
    expand: false,
    menu: 0 // 0: note, 1: TimeLine, 2: NoteBook, 3: Setting
  };

  changeMainView = route => {
    if (ROUTES.MAIN_TIMELINE === route) {
      this.setState({ menu: 1 });
    } else if (ROUTES.MAIN_SETTING === route) {
      this.setState({ menu: 3 });
    }
    this.props.handleRouting(route);
  };

  handlExpand = () => {
    // this.setState({ menu: 2 });
    this.setState({ expand: !this.state.expand });
  };

  contactsupport = () => {
    console.log("Send email");
    const uuid = this.props.user.uid;
    const userAgent = window.navigator.userAgent;

    let mailbody = "mailto:support@neolab.net";
    mailbody += "?subject=[Neo Notes]  Neo Notes can be better with your help";
    mailbody += `&body=Hello. Dear Neo Lovers. %0D%0A
    %0D%0A
    Thanks for being part of Neo Notes, we can build better service together with your help.%0D%0A
    Each one of your comment truly matters and we promise to do our best to improve service usability considering your valuable opinion.  %0D%0A
    %0D%0A
    (*Below message is system information which automatically generated. It helps us to figure out what cause the problems you have encountered. No personal information is included.) %0D%0A
    %0D%0A
    [Information] %0D%0A
    Service-UUID: 
    `;
    mailbody += uuid;
    mailbody += "%0D%0A User Agent: ";
    mailbody += userAgent;

    window.open(mailbody);
  };

  render() {
    const { classes, currentnote } = this.props;

    const { expand, menu } = this.state;
    const expandIconStyle = {
      position: "absolute",
      marginRight: 30,
      right: 0
    };
    let menutype = menu
    if (currentnote) menutype = 0
    return (
      <div className={classes.sidemenu}>
        <Divider className={classes.divider} style={{ marginBottom: 40 }} />

        <MenuItem
          className={clsx(classes.menu, {
            [classes.menuSelected]: menutype === 1
          })}
          onClick={() => this.changeMainView(ROUTES.MAIN_TIMELINE)}
        >
          <ListItemIcon className={classes.icon}>
            <NIcon.TimeLine />
          </ListItemIcon>
          <Typography variant="h3" className={classes.title}>
            Timeline
          </Typography>
        </MenuItem>

        <MenuItem
          className={classes.menuContact}
          onClick={this.handlExpand}
        >
          <ListItemIcon className={classes.icon}>
            <NIcon.NoteBook />
          </ListItemIcon>
          <Typography variant="h3" className={classes.title}>
            Notebook
          </Typography>
          {expand ? <Icons.ExpandLess style={expandIconStyle} /> : <Icons.ExpandMore style={expandIconStyle} />}
        </MenuItem>
        <NoteListModel expand={expand} />

        <Divider className={classes.divider} style={{ margin: "20px 30px" }} />

        <MenuItem
          className={clsx(classes.menu, {
            [classes.menuSelected]: menutype === 3
          })}
          onClick={() => this.changeMainView(ROUTES.MAIN_SETTING)}
        >
          <ListItemIcon className={classes.icon}>
            <Icons.SettingsOutlined />
          </ListItemIcon>
          <Typography variant="h3" className={classes.title}>
            Setting
          </Typography>
        </MenuItem>

        <MenuItem className={classes.menuContact} onClick={this.contactsupport}>
          <ListItemIcon className={classes.icon}>
            <Icons.EmailOutlined />
          </ListItemIcon>
          <Typography variant="h3" className={classes.title}>
            Contact Support
          </Typography>
        </MenuItem>

        <div className={classes.space} />

        <ListItem className={classes.logo}>
          {this.props.themeType === 0 ? (
            <img src={studioLogo} alt="NEO NOTES STUDIO" />
          ) : (
            <img src={studioLogoDark} alt="NEO NOTES STUDIO" />
          )}
        </ListItem>
      </div>
    );
  }
  componentDidMount() {}
}

SideMenu.propTypes = propTypes;
SideMenu.defaultProps = defaultProps;

export default withStyles(styles)(SideMenu);
