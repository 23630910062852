import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import PageView from "./PageView";
import { GridList, GridListTile, CircularProgress, Typography, Divider, Box } from "@material-ui/core";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    width: "100%"
  },
  title: {
    marginLeft: 'calc(10%)'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  pageColor: {
    color: theme.palette.primary.main
  }
});

const propTypes = {
  pages: PropTypes.array,
  note: PropTypes.object,
  selectPage: PropTypes.func
};

const defaultProps = {};

class PageListView extends React.Component {
  render() {
    const { classes, pages, note, selectPage } = this.props;

    let mid = (window.innerHeight - 160) / 2;
    if (!pages.length) {
      return <CircularProgress style={{ marginTop: mid }} />;
    }
    // console.log("page list view start", pages);

    return (
      <Fragment>
        <Box className={classes.title}>
          <Typography variant="subtitle1" align="left" style={{ marginTop: 99, color: "#aaa" }}>
            {this.formatedDate(note.updated)}
          </Typography>
          <Typography variant="h1" align="left"  style={{marginTop: 19}}>
            {note.title}
          </Typography>
          <Divider className={classes.divider} style={{height:2, width: 79, marginTop: 31}}/>
          <Typography className={classes.pageColor} variant="subtitle1" align="left" style={{ marginTop: 31, marginBottom: 50}}>
                  <span style={{color:"#aaa"}}> {"USE Page "} </span> {note.totalcount === 0 ? note.usingPages : note.usingPages + "/" + note.totalcount}
          </Typography>
        </Box>
        <GridList cellHeight={180} className={classes.root}>
          <GridListTile key="Subheader" cols={2} style={{ height: "auto" }} />
          {pages.map(page => (
            <PageView key={page.pageid} page={page} selectPage={selectPage} />
          ))}
        </GridList>
      </Fragment>
    );
  }

  formatedDate = (time) => {
    let date = new Date(time)
    const months = ['Jan','Feb','Mar','Apr','May','Jun', 'Jul','Aug','Sep','Oct','Nov','Dec']
    let day = date.getDay()
    let month = date.getMonth()
    let year = date.getFullYear()
    let formated = "" + day + " " + months[month] + " " + year
    return formated
  }
}

PageListView.propTypes = propTypes;
PageListView.defaultProps = defaultProps;

export default withStyles(styles)(PageListView);
