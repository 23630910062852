import * as types from '../actions/ActionTypes'

const initialState = {
  type: 0,
}

export default function theme(state = initialState, action) {
  // console.log("reducer theme", action.type)
  switch (action.type) {
    case types.THEME:
      return {type: action.theme}
    default:
      return state
  }
}