import React from "react";
import PropTypes from "prop-types";
import TimeLinePageViewDay from "./TimeLinePageViewDay";

import { CircularProgress, Typography, Box } from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles"
import NoDataView from './NoDataView'

const propTypes = {
  days: PropTypes.object,
  selectPage: PropTypes.func,
  nextPage: PropTypes.func
};

const defaultProps = {};

const styles= (theme) => ({
  title: {
    marginLeft: 'calc(10%)'
  },
  subtitle: {
    color: theme.palette.grey[200]
  }
});

class TimeLinePageView extends React.Component {
  render() {
    const { classes, days, selectPage, isMobile } = this.props;
    let mid = (window.innerHeight - 160) / 2;

    if (!days){
      return (
        <NoDataView />
      ); 
    }
    const daysArray = Object.keys(days).reverse();

    if (daysArray.length === 0) {
      return (
        <div style={{ width: "100%", marginTop: mid }}>
          <CircularProgress />
        </div>
      ); 
    }
    let totalpage = Object.values(days).reduce((sum, day) => sum + day.length, 0)
    console.log("reloding page", totalpage);
    return (
      <div
        onScroll={this.listenToScroll}
        className="TimeLinePageContainer"
        style={{ scrollTop: 0, height: "100%", overflowX: "hidden", width: "100%", overflowY: isMobile ? "hidden" :"scroll" }}
      >
        <Box className={classes.title}>

          <Typography variant="h1" align='left' gutterBottom style={{marginTop: 100}}>
            {"Hello, " + this.props.userName.split(' ')[0]}
          </Typography>
          <Typography variant="h1" align='left' gutterBottom style={{marginTop: 0}}>
            {"Records govern the memory."}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1" align='left' gutterBottom style={{marginTop: 0, marginBottom: 70}}>
            {"You have Recorded Total"} <span style={{color:"#1794b6"}}> {totalpage} {"pages."} </span>
          </Typography>
        </Box>
        
        {daysArray.map((key, index) => (
          <TimeLinePageViewDay pages={days[key]} key={index} selectPage={selectPage} />
        ))}
      </div>
    );
  }

  listenToScroll = e => {
    const heightGap = e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop;
    // console.log("Scroll Event Gap", heightGap)
    if (heightGap < 2000) {
      // console.log("loading Next Page")
      this.props.nextPage();
    }
  };
}

TimeLinePageView.propTypes = propTypes;
TimeLinePageView.defaultProps = defaultProps;

export default withStyles(styles)(TimeLinePageView)