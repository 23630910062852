import React from "react";
import IconButton from "@material-ui/core/IconButton";
import * as Icon from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { Tag } from "../Utils";

const styles = theme => ({
  bottom: {
    // marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 60,
    backgroundColor: theme.palette.background.paper, //default or paper
    zIndex: 1100
  },
  icon: {
    margin: "auto 0",
    height: 48
  }
});

class PageBottomView extends React.Component {
  zoomIn = () => {
    this.props.zoom(true);
  };

  zoomOut = () => {
    this.props.zoom(false);
  };

  nextPage = () => {
    Tag.pagedetail("next")

    this.props.nextPage(true);
  };

  prePage = () => {
    Tag.pagedetail("previous")

    this.props.nextPage(false);
  };

  zoomAtWidth = () => {
    this.props.zoomAtWidth(true);
  };

  zoomAtHeight = () => {
    this.props.zoomAtWidth(false);
  };

  render() {
    const { classes, scale } = this.props;

    let withdown = isWidthDown("sm", this.props.width);
    return (
      <div className={classes.bottom}>
        <IconButton className={classes.icon} onClick={this.prePage}>
          <Icon.ChevronLeft />
        </IconButton>

        <IconButton className={classes.icon} aria-label="NextPAge" onClick={this.nextPage}>
          <Icon.ChevronRight />
        </IconButton>
        <IconButton className={classes.icon} aria-label="ZoomOut" onClick={this.zoomOut}>
          <Icon.Remove />
        </IconButton>

        <Typography variant="button" style={{ width: 50, margin: "auto 0" }}>
          {scale + "%"}
        </Typography>

        <IconButton className={classes.icon} aria-label="ZoomIn" onClick={this.zoomIn}>
          <Icon.Add />
        </IconButton>


        <IconButton className={classes.icon} onClick={this.zoomAtHeight}>
          <Icon.Fullscreen />
        </IconButton>

        {!withdown && (
          <IconButton className={classes.icon} onClick={this.zoomAtWidth}>
            <Icon.ZoomOutMapRounded />
          </IconButton>
        )}
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(PageBottomView));
