import React from 'react';
// import {blue500, red500, greenA200} from 'material-ui/styles/colors';
import {SvgIcon} from '@material-ui/core'

export const Logo = (props) => (
  <SvgIcon {...props}>
      <path fill="none" d="M0 0h160v30H0V0z"/>
  </SvgIcon>
)

export const NoteBook = props => (
  <SvgIcon {...props}>
    <path d="M19 18H1c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v9c0 .55-.45 1-1 1zm-1-9H2v7h16V9zM2 3h16v2H2V3z"/>  
  </SvgIcon>
);

export const TimeLine = props => (
  <SvgIcon {...props}>
    <path d="M9.99 20C4.47 20 0 15.52 0 10S4.47 0 9.99 0C15.52 0 20 4.48 20 10S15.52 20 9.99 20zM10 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm-1 9V5h2v5h4v2H9v-1z"/>
  </SvgIcon>
);

export const EmptyNote = props => (
  <SvgIcon {...props}>
    <path d="M21.78 20.009H6.214a2.23 2.23 0 0 1-2.223-2.223V2.22c0-1.223 1-2.224 2.223-2.224H21.78a2.23 2.23 0 0 1 2.224 2.224v15.566a2.23 2.23 0 0 1-2.224 2.223zM22 2H6v16h16V2zm-7.006 10.012h-1.991V6.001h1.991v6.011zm-.996.998c.55 0 .996.447.996.999a.997.997 0 0 1-1.991 0c0-.552.445-.999.995-.999zM20 24l-17.822.004c-1.2 0-2.182-.982-2.182-2.182L0 4h2v18h18v2z"/>
  </SvgIcon>
);