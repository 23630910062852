import React from "react";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../routes";
import PageListView from "./PageListView";

class PageListModel extends React.Component {

  selectPage = page => {
    console.log("selected Page", page)
    this.props.selectPage(page);
    this.props.handleDrawer(false)
    this.props.history.push(ROUTES.MAIN_PAGE);
  };

  render() {
    const { note, pages } = this.props;
    return (
      <React.Fragment>
        <PageListView selectPage={this.selectPage} pages={pages} note={note}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    pages: state.pages.list,
    note: state.note.currentNote
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPage: page => dispatch(Actions.changePage(page)),
    handleDrawer: (open) => dispatch(Actions.drawer(open))
  };
};

const withPageListModel = withRouter(withFirebase(PageListModel));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPageListModel);
