import {combineReducers} from 'redux'
import login from './login'
import note from './note'
import notes from './notes'
import page from './page'
import movepage from './movepage'
import pages from './pages'
import theme from './theme'
import drawer from './drawer'

const reducer = combineReducers({note, notes, login, page, movepage, pages, theme, drawer})

export default reducer