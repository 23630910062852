import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withFirebase } from '../../Firebase'
import * as Actions from "../../store/actions";
import { withRouter } from "react-router-dom";
import ActionNote from './ActionNote';
import ActionPage from './ActionPage'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import SideMenu from './SideMenuView'

const propTypes = {};

const defaultProps = {};

class SideMenuModel extends Component {

  handleRouting = route => {
    let withdown = isWidthDown("sm", this.props.width);
    if (withdown) {
      this.props.handleDrawer(false);
    }
    this.props.selectNote(null);
    this.props.history.push(route);
  };

  render() {
    const {user} = this.props

    return (
      <Fragment>
        <SideMenu
          firebase={this.props.firebase}
          user={user}
          handleRouting={this.handleRouting}
          themeType={this.props.themeType}
          currentnote={this.props.currentnote}
        />
        <ActionNote />
        <ActionPage />
      </Fragment>
    );
  }
}

SideMenuModel.propTypes = propTypes;
SideMenuModel.defaultProps = defaultProps;


 const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    currentnote: state.note.currentNote,
    themeType: state.theme.type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectNote: (note) => dispatch(Actions.changeNote(note)),
    handleDrawer: (open) => dispatch(Actions.drawer(open))
  };
};

const withSideController = withFirebase(SideMenuModel);
const withRouterView = withRouter(withSideController);
const withWidthView  = withWidth()(withRouterView);

export default connect(mapStateToProps, mapDispatchToProps)(withWidthView)
 