let base = 'https://neonotes2-d0880.firebaseio.com/'

//{height, width, x, y}
export function getNoteSize(page) {
  let s = page.section
  let o = page.owner
  let n = page.note
  let p = page.page

  let url = 'page/' + s + '/' + o + '/' + n + + "/" + p + '.json?'
  let defaultPage = 'page/' + s + '/' + o + '/' + n + '/0.json?'
  let defaultRect = {
      height:118,
      width: 91,
      x: 5,
      y: 5
  }
  return new Promise((resove, reject) => {
    fetch(base + url)
    .then(res => res.json())
    .then(json => {
      if (!json){
        return fetch(base+defaultPage)
      } 
      resove(json)
      // console.log(json)
    })
    .then(res => res.json())
    .then(json => {
      if (!json) {
        resove(defaultRect)
      }
      resove(json)
      // console.log(json)
    })
    .catch (error => {
      console.log("error", error)
      resove(defaultRect)
    })
  })
}

/**
* Returns notemeta Info
* @param {object} note section, owner, note
* @returns {Promise} thumbnail, title, updated, zipfile, totalcount
*/
export function getNoteInfo(note) {
  let s = note.section
  let o = note.owner
  let n = note.note
  
  let url = 'note/' + s + '/' + o + '/' + n + '.json?'
  return new Promise((resove, reject) => {
    fetch(base + url)
    .then(res => res.json())
    .then(json => {
      resove(json)
      // console.log(json)
    })
    .catch (error => {
      console.error("getNoteInfo error user Dummy ", error)
      let dummy = {
        "pagecount" : 1,
        "thumbnail" : "../../assets/cover/default.png",
        "title" : "UnKnown",
        "totalcount" : "1",
        "updated" : 1523866339,
      }
      resove(dummy)
    })
  })
}

// input { page ={ section, owner, note, page} }
// {url}
export function getBackgroudImage(page, firebase) {
  let section = page.section
  let owner = page.owner
  let note = page.note
  let p = page.page
  let url = 'note/' + section + '/' + owner + '/' + note +'.json?'
  let bginfo = base + url

  // hosting
  // return new Promise((resolve, reject) => {
  //   fetch(bginfo)
  //   .then(res => res.json())
  //   .then(json => {
  //     if (json.pagecount === 1) {
  //       p = 1
  //     }else if(json.pagecount === 2) {
  //       p = p % 2
  //       if (p === 0) p = 2
  //     }
      
  //     let imageref = "background/" + note + "/" + section + "_" + owner + "_" + note + "_" + p +".jpg"
  //     resolve('../../assets/'+ imageref) // background를 Hosting에서 가져옴, 주석처리시 Stroage에서 가져옴
  //   })
  //   .catch(() => {
  //     console.log("json error");
  //     resolve("")
  //   })
  // })
  // From Fire base Storage
  return new Promise((resolve, reject) => {
    fetch(bginfo)
    .then(res => res.json())
    .then(json => {
      var storageRef = firebase.Storage();
      if (json.pagecount === 1) {
        p = 1
      }else if(json.pagecount === 2) {
        p = p % 2
        if (p === 0) p = 2
      }
      let imageref = "background/" + note + "/" + section + "_" + owner + "_" + note + "_" + p +".jpg"
      var starsRef = storageRef.child(imageref);
      return starsRef.getDownloadURL()
    })
    .then(url => {
      resolve(url)
    })
    .catch((error) => {
      console.log("user default", error);
      resolve("../../assets/img/emptypage.png")
    })
  })
}