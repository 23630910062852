import { fabric } from "fabric";

export default class FabricRender {
  constructor(canvasName) {
    this.canvasName = canvasName;
    this.canvas = null;
    this.frameconfig = 1;
    this.bgcolor = 0;
    this.bgurl = "";
    this.originSize = { w: 800, h: 1000 };
    this.defalutLineWidth = 500; // 클수록 글씨가 앏아짐
    this.strokWidth = 800;
    this.strokHeight = 1000;
    this.lineScale = [1,3,5,7,10]
  }

  resize = size => {
    var zoom = size.w / this.originSize.w;
    this.canvas.setZoom(zoom);
    this.canvas.setHeight(size.h);
    this.canvas.setWidth(size.w);
  };

   //step 1: canvas set size and background image
  setCanvas = (size, bgurl) => {
    this.bgurl = bgurl;
    this.originSize = size;
    this.canvas = new fabric.Canvas(this.canvasName, {
      backgroundColor: "rgb(255,255,255)",
      // selectionColor: 'blue',
      selection: false,
      controlsAboveOverlay: true,
      centeredScaling: true,
      allowTouchScrolling: true,
      selectionLineWidth: 2,
      width: size.w,
      height: size.h
    });
    // this.canvas.isDrawingMode = true
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        bgurl,
        img => {
          img.scaleToWidth(size.w);
          // img.scaleToHeight(size.h)

          img.selectable = false;
          this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));
          console.log("image size", size, img.width, img.height);
          resolve();
        },
        { crossOrigin: "Anonymous" }
      );
    });
  };

  drawingStrokeThumbnail = (st, rect, size) => {
    let scaleX = size.w / rect.width;
    let scaleY = size.h / rect.height;
    console.log("drawGDData Line", st.length, scaleX, scaleY);
    st.forEach(stroke => {
      var pointArray = [];
      stroke.getDots().forEach(dot => {
        let p = dot.force;
        let x = dot.x - rect.x;
        let y = dot.y - rect.y;
        x *= scaleX;
        y *= scaleY;
        pointArray.push({
          x: x,
          y: y,
          p: p
        });
      });

      let color = stroke.getColor();
      const pathOption = {
        objectCaching: false
      };
      // let penType = stroke.extraData[0]; // 0: NeoPen, 1: TouchPen, 2: HighlightPen

      let pathData = this.drawCurvePath(pointArray)
      pathOption.fill="transparent"
      pathOption.stroke = color
      pathOption.strokeWidth = parseFloat(stroke.penThickness) * 0.5;
      if (pathOption.strokeWidth === 0) pathOption.strokeWidth = 0.3
      
      pathOption.strokeLineCap = "round"

      var path = new fabric.Path(pathData, pathOption);

      path.selectable = false;
      this.canvas.add(path);
    });
  }

  // Google Drive Format
  drawingStrokeGD = (st, rect, size) => {
    this.strokWidth = size.w;
    this.strokHeight = size.h;
    let scaleX = size.w / rect.width;
    let scaleY = size.h / rect.height;
    console.log("drawGDData", st.length, scaleX, scaleY);
    let lineWidth = this.defalutLineWidth / size.w;
    console.log("linewidth", lineWidth);

    st.forEach(stroke => {
      var pointArray = [];
      stroke.getDots().forEach(dot => {
        let p = dot.force;
        let x = dot.x - rect.x;
        let y = dot.y - rect.y;
        x *= scaleX;
        y *= scaleY;
        pointArray.push({
          x: x,
          y: y,
          p: p
        });
      });

      let color = stroke.getColor();
      const pathOption = {
        objectCaching: false
      };
      let penType = stroke.extraData[0]; // 0: NeoPen, 1: TouchPen, 2: HighlightPen
      // console.log("PathInfo", pointArray[0])
      let pathData
      if (penType === 0) {
        // console.log("NeoPen Type");
        pathOption.fill = color
        pathData = this.drawPath(pointArray, lineWidth);
      } else if (penType === 1) {
        console.log("TouchPen Type");
        pathOption.fill="transparent"
        pathOption.stroke = color
        pathOption.strokeWidth = this.lineScale[stroke.penThickness]
        pathOption.strokeLineCap = "round"
        pathData = this.drawCurvePath(pointArray)
      } else if (penType === 2) {
        console.log("HighlightPen Type");
        pathOption.fill="transparent"
        pathOption.stroke = color
        pathOption.strokeWidth = this.lineScale[stroke.penThickness]
        pathOption.strokeLineCap = "round"
        pathData = this.drawCurvePath(pointArray)
      } else {
        console.log("Not Supported Pen Type");
        return
      }

      if (parseInt(stroke.penThickness) > 1) {
        pathOption.stroke = color
        pathOption.strokeWidth = stroke.penThickness;
      }

      var path = new fabric.Path(pathData, pathOption);

      path.selectable = false;
      path.evented = false
      this.canvas.add(path);
    });
  };

  drawLinePath = point => {
    const len = point.length
    if (len < 1){
      return
    }

    let path = ""
    path += "M" + point[0].x + "," + point[0].y;

    for (var i = 1; i < len-1; i++) {
      const p = point[i]

      path += ("L" + p.x + ", " + p.y)
    }
    return path
  };

  drawCurvePath = (point) => {
    if (point.length < 1){
      return
    }
    let bezier = ""
    bezier += "M" + point[0].x + "," + point[0].y;

    let n = point.length - 1
    var controlPoints = []
        
    for (let i = 0 ; i < n; i ++) {
        let p = point[i]
        
        if (controlPoints.length < 5) {
            controlPoints.push(p)
            continue
        }
        
        let endPoint = {
          x: (controlPoints[2].x + p.x)/2,
          y: (controlPoints[2].y + p.y)/2
        }

        bezier += this.point3Curve(controlPoints[1], controlPoints[2], endPoint);
        controlPoints = [endPoint, p]
    }
    let p = point[n]

    while (controlPoints.length < 5){
      controlPoints.push(p)
    }
    bezier += this.point3Curve(controlPoints[1], controlPoints[2], p);
    
    return bezier
  }

  drawPath = (point, lineWidth) => {
    if (point.length < 3) {
      return;
    }
    var bezier = "";

    let penThicknessScaler = lineWidth;
    let lineThicknessScale = 1.0 / penThicknessScaler;
    let scaled_pen_thickness = 1.0 * lineThicknessScale; //* 7
    // first 1.0f --> lineScale
    var x0;
    var x1;
    var x2;
    var x3;
    var y0;
    var y1;
    var y2;
    var y3;
    var p0;
    var p1;
    var p2;
    var p3;
    var vx01;
    var vy01;
    var vx21;
    var vy21;
    // unit tangent vectors 0->1 and 1<-2
    var norm;
    var n_x0;
    var n_y0;
    var n_x2;
    var n_y2;
    // the normals
    var temp = { x: 0, y: 0 };
    var endPoint = { x: 0, y: 0 };
    var controlPoint1 = { x: 0, y: 0 };
    var controlPoint2 = { x: 0, y: 0 };
    // the first actual point is treated as a midpoint
    x0 = point[0].x + 0.1;
    y0 = point[0].y;
    p0 = point[0].p;
    x1 = point[1].x + 0.1;
    y1 = point[1].y;
    p1 = point[1].p;
    vx01 = x1 - x0;
    vy01 = y1 - y0;
    // instead of dividing tangent/norm by two, we multiply norm by 2
    norm = Math.sqrt(vx01 * vx01 + vy01 * vy01 + 0.0001) * 2.0;
    vx01 = (vx01 / norm) * scaled_pen_thickness * p0;
    vy01 = (vy01 / norm) * scaled_pen_thickness * p0;
    n_x0 = vy01;
    n_y0 = -vx01;
    // Trip back path will be saved.
    var pathPointStore = [];
    temp.x = x0 + n_x0;
    temp.y = y0 + n_y0;

    endPoint.x = x0 + n_x0;
    endPoint.y = y0 + n_y0;
    controlPoint1.x = x0 - n_x0 - vx01;
    controlPoint1.y = y0 - n_y0 - vy01;
    controlPoint2.x = x0 + n_x0 - vx01;
    controlPoint2.y = y0 + n_y0 - vy01;
    //Save last path. I'll be back here....
    let ep = this.clone(endPoint);
    let cp1 = this.clone(controlPoint1);
    let cp2 = this.clone(controlPoint2);
    pathPointStore.push({
      endPoint: ep,
      controlPoint1: cp1,
      controlPoint2: cp2
    });

    // drawing setting
    bezier += "M" + temp.x + "," + temp.y;
    for (var i = 2; i < point.length - 1; i++) {
      x3 = point[i].x;
      // + 0.1f;
      y3 = point[i].y;
      p3 = point[i].p;
      x2 = (x1 + x3) / 2.0;
      y2 = (y1 + y3) / 2.0;
      p2 = (p1 + p3) / 2.0;
      vx21 = x1 - x2;
      vy21 = y1 - y2;
      norm = Math.sqrt(vx21 * vx21 + vy21 * vy21 + 0.0001) * 2.0;
      vx21 = (vx21 / norm) * scaled_pen_thickness * p2;
      vy21 = (vy21 / norm) * scaled_pen_thickness * p2;
      n_x2 = -vy21;
      n_y2 = vx21;
      if (norm < 0.6) {
        continue;
      }
      // The + boundary of the stroke
      endPoint.x = x2 + n_x2;
      endPoint.y = y2 + n_y2;
      controlPoint1.x = x1 + n_x0;
      controlPoint1.y = y1 + n_y0;
      controlPoint2.x = x1 + n_x2;
      controlPoint2.y = y1 + n_y2;
      bezier += this.point3Curve(controlPoint1, controlPoint2, endPoint);

      // THe - boundary of the stroke
      endPoint.x = x0 - n_x0;
      endPoint.y = y0 - n_y0;
      controlPoint1.x = x1 - n_x2;
      controlPoint1.y = y1 - n_y2;
      controlPoint2.x = x1 - n_x0;
      controlPoint2.y = y1 - n_y0;
      let ep = this.clone(endPoint);
      let cp1 = this.clone(controlPoint1);
      let cp2 = this.clone(controlPoint2);
      pathPointStore.push({
        endPoint: ep,
        controlPoint1: cp1,
        controlPoint2: cp2
      });
      x0 = x2;
      y0 = y2;
      p0 = p2;
      x1 = x3;
      y1 = y3;
      p1 = p3;
      vx01 = -vx21;
      vy01 = -vy21;
      n_x0 = n_x2;
      n_y0 = n_y2;
      //
    }
    // the last actual point is treated as a midpoint
    x2 = point[point.length - 1].x;
    // + 0.1f;
    y2 = point[point.length - 1].y;
    p2 = point[point.length - 1].p;
    vx21 = x1 - x2;
    vy21 = y1 - y2;
    norm = Math.sqrt(vx21 * vx21 + vy21 * vy21 + 0.0001) * 2.0;
    vx21 = (vx21 / norm) * scaled_pen_thickness * p2;
    vy21 = (vy21 / norm) * scaled_pen_thickness * p2;
    n_x2 = -vy21;
    n_y2 = vx21;
    endPoint.x = x2 + n_x2;
    endPoint.y = y2 + n_y2;
    controlPoint1.x = x1 + n_x0;
    controlPoint1.y = y1 + n_y0;
    controlPoint2.x = x1 + n_x2;
    controlPoint2.y = y1 + n_y2;
    bezier += this.point3Curve(controlPoint1, controlPoint2, endPoint);
    endPoint.x = x2 - n_x2;
    endPoint.y = y2 - n_y2;
    controlPoint1.x = x2 + n_x2 - vx21;
    controlPoint1.y = y2 + n_y2 - vy21;
    controlPoint2.x = x2 - n_x2 - vx21;
    controlPoint2.y = y2 - n_y2 - vy21;
    bezier += this.point3Curve(controlPoint1, controlPoint2, endPoint);

    endPoint.x = x0 - n_x0;
    endPoint.y = y0 - n_y0;
    controlPoint1.x = x1 - n_x2;
    controlPoint1.y = y1 - n_y2;
    controlPoint2.x = x1 - n_x0;
    controlPoint2.y = y1 - n_y0;
    bezier += this.point3Curve(controlPoint1, controlPoint2, endPoint);

    // Trace back to the starting point
    // console.log("reverse start", pathPointStore)
    while (pathPointStore.length) {
      var repath = pathPointStore.pop();
      bezier += this.point3Curve(repath.controlPoint1, repath.controlPoint2, repath.endPoint);
    }
    return bezier;
  };

  point3Curve = (p1, p2, p3) => {
    // bezier.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y)
    return "C" + p1.x + ", " + p1.y + "," + p2.x + ", " + p2.y + "," + p3.x + "," + p3.y;
  };

  clone = obj => {
    if (obj === null || typeof obj !== "object") return obj;

    var copy = obj.constructor();

    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = this.clone(obj[attr]);
      }
    }
    return copy;
  };
}
