import React, { Component } from 'react';
import './App.css';

import { BrowserRouter as Router, Switch } from "react-router-dom";
// import * as ROUTES from './routes'

import * as Actions from './store/actions'
import { connect } from 'react-redux'
import { withFirebase } from './Firebase';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { GDApi, Database} from "./views/Utils";
import * as neolabTheme from "./theme"

// Routing
import DefaultLayout from './container';
import Home from './views/Page/Home'

class App extends Component {
  state = {
    checkedLogin: false
  };
  render() {
    const { themeType } = this.props;
    let theme = neolabTheme.theme;
    if (themeType === 1) {
      theme = neolabTheme.theme1;
    }
    // console.log("theme in app", theme.themeName, theme.palette.type);
    if (!this.state.checkedLogin) {
      return <div />;
    }
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <Switch>
              {this.props.user ? <DefaultLayout/> : <Home/>}
              {/* <Route exact path="/" component={DefaultLayout} />
              <Route path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.MAIN} component={DefaultLayout} /> */}
            </Switch>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }

  componentDidMount() {
    GDApi.initGoogleDrive(this.props.firebase, (res) => {
      if(res){
        console.log("Google Driver and User Setting success")
      }else {
        console.log("Google Driver logout")
        this.props.changeTheme(0);
        this.props.firebase
        .doSignOut().then(() => {
          console.log("firebase ");
        })
        .catch(error => {
          console.log(error);
        });
      }
    });

    this.props.firebase.Auth().onAuthStateChanged(user => {
      if (user) {
        console.log("App User Info", user)
        this.props.onUserChange(user);
        Database.initDatabaseWithFirebaseAndUser(this.props.firebase,user.uid);
        this.userInfoUpdate();

      } else {
        this.props.onUserChange(null);
      }
      this.setState({ checkedLogin: true });
    });

 
  }

  userInfoUpdate = () => {
    Database.getTheme().then(themeType => {
      this.props.changeTheme(themeType);
      console.log("set User Info: ThemeType: ", themeType);
    });
  };
}
const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    themeType: state.theme.type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUserChange: (user) => dispatch(Actions.login(user)),
    changeTheme: (newtheme) => dispatch(Actions.theme(newtheme))
  }
}

const withFirebaseApp = withFirebase(App)

export default connect(mapStateToProps, mapDispatchToProps)(withFirebaseApp)
