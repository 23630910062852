import React from "react";
import { Typography } from "@material-ui/core";
import { EmptyNote}  from "../../icon"

const EmptyNoteBook = () => {
  return (
    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginBottom: 20}}>
      <EmptyNote style={{color: "#E9E9E9", margin: 20}}></EmptyNote>
      <Typography variant='body1' style={{ color: "#aaa", fontWeight:600 }}>
        {"Empty Notebook"}
      </Typography>
    </div>
  );
};

export default EmptyNoteBook;