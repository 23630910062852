import React from "react";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

const mainsvg = "../../assets/img/main.svg";

class MainView extends React.Component {
  render() {
    return (
      <div style={{ marginLeft: "calc(12%)", marginTop: 100, display: "flex", flexDirection: "column", minWidth: 300 }}>
        <img src={mainsvg} alt="Main" width="61" height="75" style={{ marginRight: "auto" }} />

        <Typography variant="h1" align="left" style={{ marginTop: 70 }}>
          {"Hi!"} {this.props.user.displayName.split(" ").shift()}
        </Typography>
        <Typography variant="h1" align="left" style={{ marginTop: 10 }}>
          {"I'm so happy you're come."}
        </Typography>

        <Typography variant="subtitle1" align="left" style={{ marginTop: 25, color: "#aaa", fontWeight: 500 }}>
          {"Select"} <span style={{ color: "#1794b6" }}> {"Notebook"}</span> {"from the right menu to get the page."}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainView);
