import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../Firebase";
import { connect } from "react-redux";
import * as ROUTES from "../../routes";
import * as GDApi from "../Utils/GoogleDrive/GDApi";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import * as Icons from "@material-ui/icons";
import { ButtonBase, Box, IconButton } from "@material-ui/core";
import {Tag} from "../Utils"
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// assets
const logosvg = "../../assets/home/logo-1.svg";
const btnLogin = "../../assets/home/btn-login.png";
const btnAndroid = "../../assets/home/btn-android.png";
const btnWindow = "../../assets/home/btn-window.png";
const btniOS = "../../assets/home/btn-ios.png";
// const imgMainbg = "../../assets/home/main_bg.png"
const imgvisual = "../../assets/home/visual.png";
const thumb_01 = "../../assets/home/func-01.png";
const thumb_02 = "../../assets/home/func-02.png";
const thumb_03 = "../../assets/home/func-03.png";
const thumb_04 = "../../assets/home/func-04.png";

const styles = theme => ({
  root: {
    margin: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper
  },
  [theme.breakpoints.up("sm")]: {
    top:{
      marginLeft: "calc(12%)",
      position: "realtive",
      backgroundImage: "url('../../assets/home/mainbg01.png')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "200px 0px",
      height: 1400
    },
    title1: {
      fontSize: 60,
      fontWeight: 300
    },
    title2: {
      fontSize: 50,
      fontWeight: 300
    },
    subtitle1: {
      lineHeight: 1.5
    },
    midItem1: {
      marginLeft: "calc(12%)",
      maxWidth: 500,
      marginTop: 250,
      width: 400
    },
  },
  [theme.breakpoints.down("sm")]: {
    top:{
      marginLeft: "calc(12%)",
      position: "realtive",
      backgroundImage: "url('../../assets/home/mainbg_sm.png')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "30px 580px",
      height: 900
    },
    title1: {
      fontSize: 40,
      fontWeight: 300
    },
    title2: {
      fontSize: 40,
      fontWeight: 300
    },
    subtitle1: {
      lineHeight: 1.5
    },
    midItem1: {
      marginLeft: "calc(12%)",
      maxWidth: 500,
      marginTop: 50,
      width: 400
    },
  },
  device: {
    marginLeft: "calc(12%)",
    marginRight: "calc(12%)",
    textAlign: "right",

    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
      marginBottom: 140
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 144,
      marginBottom: 140
    }
  },
  mid: {
    backgroundColor: theme.palette.primary.main,
  },
  midItem2: {
    marginTop: 87,
    marginBottom: 86,
    marginRight: 0
  },
  slideBox: {
    marginRight: "calc(12%)",
    // width: "calc(min(100%, 527px))",
    maxWidth: 540
  },
  img: {
    width: '100%',
  },
  bottom: {
    marginTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      marginRight: 30
    }
  },
  bg02: {
    position: "absolute",
    top: 1247,
    left: -200
  }
});

const midtitle1 = [
  "Connect to Google Drive",
  "With Timelines, Navigate quickly and easily",
  "Per notebook navigation",
  "Theme option"
];
const midtitle2 = [
  "Simply check pages stored on Google Drive",
  "Check your notes in the most recent order.",
  "Check the pages per notebook",
  "Check yourt notes with sight friendly dark mode theme and basic white mode theme."
];
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
  }

  goMainView = () => {
    this.props.history.push(ROUTES.MAIN);
  };

  login = () => {
    if (GDApi.ready()) {
      GDApi.login();
    } else {
      alert("try again");
    }
  };

  // for SWipeable
  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleStepChange = step => {
    this.setState({ activeStep: step });
  };

  windowStore = () => {
    Tag.homelink(0)
    window.open("https://www.microsoft.com/ko-kr/p/neo-notes/9n67pcq4mtcv?activetab=pivot:overviewtab");
  };

  googlePlay = () => {
    Tag.homelink(1)
    window.open("https://play.google.com/store/apps/details?id=kr.neolab.neonote");
  };

  appStore = () => {
    Tag.homelink(2)
    window.open("https://appsto.re/kr/vKIM2.i");
  };


  feedback = () => {
    console.log("Send email");
    const userAgent = window.navigator.userAgent;

    let mailbody = "mailto:support@neolab.net";
    mailbody += "?subject=[Neo Notes]  Neo Notes can be better with your help";
    mailbody += `&body=Hello. Dear Neo Lovers. %0D%0A
    %0D%0A
    Thanks for being part of Neo Notes, we can build better service together with your help.%0D%0A
    Each one of your comment truly matters and we promise to do our best to improve service usability considering your valuable opinion.  %0D%0A
    %0D%0A
    (*Below message is system information which automatically generated. It helps us to figure out what cause the problems you have encountered. No personal information is included.) %0D%0A
    %0D%0A
    [Information] %0D%0A
    `;
    mailbody += "%0D%0A User Agent: ";
    mailbody += userAgent;

    window.open(mailbody);
  };
  // Rendering Page
  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    if (this.props.user) {
      console.log("user", this.props.user);
      this.goMainView();
    }

    return (
      <div className={classes.root}>
        {/* Top */}
        <div className={classes.top}>
          <Box display="flex" flexDirection="column" justifyContent="flex-start">
            <img src={logosvg} alt="LOGO" width="42" height="24" style={{ marginTop: 99, marginRight: "auto" }} />
            <Typography variant="h2" align="left" style={{  fontWeight: 300, fontFamily: "Noto Serif", marginTop: 70 }}>
              Welcome to
            </Typography>
            <Typography className={classes.title1} variant="h1" align="left" style={{ marginTop: 30 }}>
              {"NEO NOTES"}
            </Typography>
            <Box display="flex" flexDirection="row" style={{ marginTop: 10 }}>
              <Typography className={classes.title1} variant="h1" align="left">
                {"WEB SERVICE"}
              </Typography>
              <Box display={{ xs: "none", sm: "block" }}>
                <Typography
                  variant="h2"
                  align="left"
                  color="primary"
                  style={{ marginTop: 10, marginLeft: 19, fontFamily: "Noto Serif", fontStyle: "italic" }}
                >
                  {"Beta 1.0"}
                </Typography>
              </Box>
            </Box>
            <Box display={{ xs: "none", sm: "block" }}>
              <Box display="flex">
                <ButtonBase onClick={this.login} style={{ marginTop: 70, marginLeft: 0 }}>
                  <img src={btnLogin} width="270" height="62" alt="Sign in with Google" />
                </ButtonBase>
              </Box>
            </Box>

            <Box display={{ xs: "block", sm: "none" }}>
              <Typography
                variant="h2"
                align="left"
                color="primary"
                style={{ marginTop: 30, fontFamily: "Noto Serif", fontStyle: "italic" }}
              >
                {"Beta 1.0"}
              </Typography>
              <Box display="flex">
                <ButtonBase onClick={this.login} style={{ marginTop: 70, marginLeft: 0 }}>
                  <img src={btnLogin} width="187" height="43" alt="Sign in with Google" />
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </div>

        {/* Device */}
        <Box className={classes.device} display="flex" flexDirection="column">
          <Typography variant="h4" color="primary">
            Download Center
          </Typography>
          <Typography className={classes.title2} variant="h1" style={{ marginTop: 29 }}>
            {"Available on Any device"}
          </Typography>
          <Typography className={classes.subtitle1} variant="h2" style={{ fontWeight: 300, marginTop: 29 }}>
            {"With NeoNotes, easily exchange your ideas  everywhere"}
          </Typography>
          {/* Large */}
          <Box display={{ xs: "none", sm: "block" }}>
            <Box display="flex" flexDirection="row-reverse" style={{ marginTop: 60 }}>
              <ButtonBase onClick={this.googlePlay}>
                <img src={btnAndroid} alt="GooglePlay" style={{ margin: 5, width: 140, height: 45 }} />
              </ButtonBase>
              <ButtonBase onClick={this.appStore}>
                <img src={btniOS} alt="AppStore" style={{ margin: 5, width: 140, height: 45 }} />
              </ButtonBase>
              <ButtonBase onClick={this.windowStore}>
                <img src={btnWindow} alt="WindowStore" style={{ margin: 5, width: 140, height: 45 }} />
              </ButtonBase>
            </Box>
          </Box>
          {/* Small */}
          <Box display={{ xs: "block", sm: "none" }}>
            <Box display="flex" flexDirection="column" style={{ marginTop: 60 }}>
              <ButtonBase onClick={this.googlePlay} style={{ margin: 5, marginLeft:'auto', marginRight: 0, width: 140, height: 45 }}>
                <img src={btnAndroid} alt="GooglePlay" style={{width: 140, height: 45 }} />
              </ButtonBase>
              <ButtonBase onClick={this.appStore} style={{ margin: 5, marginLeft:'auto', marginRight: 0, width: 140, height: 45 }}>
                <img src={btniOS} alt="AppStore" style={{ width: 140, height: 45 }} />
              </ButtonBase>
              <ButtonBase onClick={this.windowStore} style={{ margin: 5, marginLeft:'auto', marginRight: 0, width: 140, height: 45 }}>
                <img src={btnWindow} alt="WindowStore" style={{  width: 140, height: 45 }} />
              </ButtonBase>
            </Box>
          </Box>
        </Box>

        {/* Mid */}
        <Grid container justify="space-between" className={classes.mid}>
          <Grid item className={classes.midItem1}>
            <Typography variant="h4" align="left" style={{ color: "#7ae3ff" }}>
              Main Function
            </Typography>
            <Typography className={classes.title2} variant="h1" align="left" style={{ marginTop: 26, color: "white" }}>
              Functional
            </Typography>
            <Typography className={classes.title2} variant="h1" align="left" style={{  marginTop: 0, color: "white" }}>
              Introduction
            </Typography>
            <Typography variant="h2" align="left" style={{ marginTop: 100, color: "white", height: 50 }}>
              {midtitle1[activeStep]}
            </Typography>
            <Typography variant="subtitle1" align="left" style={{ marginTop: 10, color: "white",  height: 50 }}>
              {midtitle2[activeStep]}
            </Typography>
            <Box display="flex" flexDirection="row" style={{ marginTop: 37 }}>
              <IconButton
                disabled={activeStep === 0}
                onClick={this.handleBack}
                style={{ color: activeStep !== 0 && "white" }}
              >
                <Icons.ArrowBackIosOutlined />
              </IconButton>
              <IconButton
                disabled={activeStep === 3}
                onClick={this.handleNext}
                style={{ color: activeStep !== 3 && "white" }}
              >
                <Icons.ArrowForwardIosOutlined />
              </IconButton>
            </Box>
          </Grid>
          <Grid item className={classes.midItem2}>
            <Box className={classes.slideBox}>
              <AutoPlaySwipeableViews
                axis={"x"}
                index={activeStep}
                onChangeIndex={this.handleStepChange}
                enableMouseEvents
              >
                <div key={1}>
                  <img className={classes.img} src={thumb_01} alt={"1"} />
                </div>
                <div key={2}>
                  <img className={classes.img} src={thumb_02} alt={"2"} />
                </div>
                <div key={3}>
                  <img className={classes.img} src={thumb_03} alt={"3"} />
                </div>
                <div key={4}>
                  <img className={classes.img} src={thumb_04} alt={"3"} />
                </div>
              </AutoPlaySwipeableViews>
            </Box>
          </Grid>
        </Grid>

        {/* Bottom */}
        <div className={classes.bottom}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h4" align="center" color="primary">
              {"Contact US"}
            </Typography>
            <Typography className={classes.title2} variant="h1" align="center" style={{ marginTop: 29 }}>
              {"Submit your idea"}
            </Typography>
            <Typography className={classes.subtitle1} variant="h2" align="center" style={{ fontWeight: 300, marginTop: 28, marginBottom: 84 }}>
              {"Your valuable feedback will be very helpful for Neo Notes Web"}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: 223, height: 60, margin: "0 auto" }}
              onClick={this.feedback}
            >
              Submit feedback
            </Button>
          </Box>
        </div>
        <Box display={{ xs: "none", sm: "block" }} className={classes.bg02}>
          <img src={imgvisual} alt="" />
        </Box>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const withHome = withStyles(styles)(withRouter(withFirebase(Home)));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHome);
