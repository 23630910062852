export const MAIN = '/main';
export const HOME = '/home';

// Main View Router
export const MAIN_TIMELINE = '/main/timeline';
export const MAIN_PAGE_LIST= '/main/pagelist';
export const MAIN_PAGE_LIST_DRIVE= '/main/googledrivelist';
export const MAIN_PAGE = '/main/pagedetail';

//DEV Mode
export const MAIN_TRASH = '/main/trash';
export const MAIN_SETTING = '/main/setting';
