import * as types from "./ActionTypes"

export function login(user) {
  return {
    type: types.LOGIN,
    user
  }
}

export function noteList(notes) {
  return {
    type: types.NOTE_LIST,
    notes
  }
}

export function changeNote(note) {
  return {
    type: types.CHANGE_NOTE,
    note
  }
}

export function pageList(pages) {
  return {
    type: types.PAGE_LIST,
    pages
  }
}

export function changePage(page) {
  return {
    type: types.CHANGE_PAGE,
    page
  }
}

export function movePage(isNext) {
  return {
    type: types.MOVE_PAGE,
    isNext: isNext
  }
}

export function theme(theme) {
  return {
    type: types.THEME,
    theme
  }
}

export function drawer(open) {
  return {
    type: types.DRAWER,
    open
  }
}