import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withFirebase } from '../../Firebase'
import * as Actions from "../../store/actions";
import {Database} from '../Utils'

class ActionNote extends Component {

  render() {
    return (
      <div>
        
      </div>
    )
  }
  
  shouldComponentUpdate(nextPros, nextState) {
    //Timeline일 경우
    if (!nextPros.note) {
      return false
    }
    // notetype: 'drive' or 'firebase'  
    // notetype이 'drive'이면 페이지를 가져오지 않는다
    if (nextPros.note.notetype === 'drive'){
      return false
    }
    //노트를 바꾸었을때
    const noteChange = nextPros.note !== this.props.note
    if (noteChange) {
      let user = this.props.firebase.getUser()
      if (user) {
        this.props.onPages([])
        Database.getPageList(nextPros.note.noteid )
        .then(pages => {
          console.log("get pages", pages.length)
          console.log("first page info", pages[0].page)
          let sorting = (p1, p2) => p1.page - p2.page
          let sortedpages = pages.sort(sorting)
          this.props.onPages(sortedpages)
        })
        .catch(e => {
          console.log("error", e)
          this.setState({pages: []})
        });
      }
    }
    return false
  }
}


const mapStateToProps = (state) => ({
  note: state.note.currentNote
})

const mapDispatchToProps = (dispatch) => {
  return {
    onPages: (pages) => dispatch(Actions.pageList(pages)),
  };
};

const withActionNote = withFirebase(ActionNote)

export default connect(mapStateToProps, mapDispatchToProps)(withActionNote)
