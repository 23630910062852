import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withFirebase } from '../../Firebase'
import * as Actions from "../../store/actions";

class ActionPage extends Component {
  render() {
    return (
      <div>
        
      </div>
    )
  }

  shouldComponentUpdate(nextPros, nextState) {
    //Page <> 될때
    if (this.props.movepage.count !== nextPros.movepage.count) {
      if (nextPros.movepage.next) {
        console.log("next page");
        let index = this.props.pages.findIndex(e => e.page === this.props.page.page)
        let nextPage = this.props.pages[index + 1]
        if (nextPage) {
          this.props.selectPage(nextPage)
        }
      } else {
        console.log("prev page");
        let index = this.props.pages.findIndex(e => e.page === this.props.page.page)
        let prevPage = this.props.pages[index - 1]
        if (prevPage) {
          this.props.selectPage(prevPage)
        }
      }
    }
    return false
  }

}// End Class


const mapStateToProps = (state) => ({
  pages: state.pages.list,
  page: state.page.currentPage,
  movepage: state.movepage
})

const mapDispatchToProps = (dispatch) => {
  return {
    selectPage: (page) => dispatch(Actions.changePage(page)),
  };
};

const withActionPage = withFirebase(ActionPage)

export default connect(mapStateToProps, mapDispatchToProps)(withActionPage)

