import React from "react";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { GDApi, Database } from "../Utils";
import { withFirebase } from "../../Firebase";
import { Tag } from "../Utils";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Grid,
  Divider,
  Box
} from "@material-ui/core";

const themeDarkSel = "../../assets/img/darkSel.svg";
const themeDarkNor = "../../assets/img/darkNor.svg";
const themeWhiteSel = "../../assets/img/whiteSel.svg";
const themeWhiteNor = "../../assets/img/whiteNor.svg";

const styles = theme => ({
  settingView: {
    width: 'calc(100% - 180px)',
    marginLeft: 'calc(12%)',
    marginBottom: 50
  },
  container: {
    marginTop: 60,
  },
  contents: {
    display: "flex", 
    flexDirection: "column",
    width: 580
  },
  title: {},
  subtitle: {
    fontWeight: 500,
    color: theme.palette.grey[400],
    marginBottom: 18
  },
  description: {
    marginBottom: 40,
    color: theme.palette.grey[300]
  },
  themeIcon: {
    width: 270,
    height: 150,
    paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    margin: 10,
    "&:hover": {
      opacity: 0.8,
      "& p": {
        color: "#1794b6"
      }
    }
  }
});

class SettingView extends React.Component {
  state = {
    open: false // Delete Data Dialog
  };

  handleThemeChange = theme => {
    Tag.themeChange(parseInt(theme))
    const themeType = parseInt(theme);
    Database.updateTheme(themeType);
    this.props.changeTheme(themeType);
  };

  logout = () => {
    this.props.changeTheme(0);
    this.props.onNotes([]);
    GDApi.logout();
    this.props.firebase
      .doSignOut()
      .then(() => {
        console.log("logout ");
      })
      .catch(error => {
        console.log(error);
      });
  };

  deleteAllData = () => {
    this.handleClose();
    console.log("delete data start");
    this.props.onNotes([]);
    Database.getNoteList()
      .then(notes => {
        console.log("delete note start count", notes.length);
        notes.forEach(note => {
          console.log("Delete all date", note.noteid);
          Database.deleteNoteBatch(note.noteid)
            .then(() => {
              console.log("delete success", note.noteid);
            })
            .catch(err => console.log("note delete err", note.noteid));
        });
      })
      .catch(err => console.log("get note list error", err));
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, themeType } = this.props;
    let email = "";
    if (this.props.user) {
      email = this.props.user.email;
    }
    let whiteStyle = {
      border: "2px ridge #1794b6",
      "& p": {
        color: "#1794b6"
      }
    };
    let darkStyle = whiteStyle;
    if (themeType === 0) {
      darkStyle = { border: "1px ridge #cfcfcf"};
    } else if (themeType === 1) {
      whiteStyle = { };
    }

    return (
      <div className={classes.settingView}>
        <Typography className={classes.title} variant="h1" align="left" style={{ marginTop: 100 }}>
          {"Setting"}
        </Typography>
        <Divider style={{ marginTop: 130 }} />
        {/* Theme */}
        <Grid container className={classes.container}>
          <Grid item  style={{ width: 240 }}>
            <Typography className={classes.subtitle} variant="h2" align="left">
              {"Theme"}
            </Typography>
          </Grid>
          <Grid item className={classes.contents}>
            <Typography className={classes.description} variant="body1" align="left">
              {"NeoNote web service supports White / Dark mode themes. "}
            </Typography>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Paper
                square={true}
                elevation={0}
                className={classes.themeIcon}
                onClick={() => this.handleThemeChange(0)}
                style={whiteStyle}
              >
                {themeType === 0 ? <img src={themeWhiteSel} alt="white" /> : <img src={themeWhiteNor} alt="white" />}
                <Typography variant="body2" style={{ marginTop: 15, color: themeType === 0 ?"#1794b6": "#aaa"  }}>
                  {"White Mode"}
                </Typography>
              </Paper>
              <Paper
                square={true}
                elevation={0}
                className={classes.themeIcon}
                onClick={() => this.handleThemeChange(1)}
                style={darkStyle}
              >
                {themeType === 1 ? <img src={themeDarkSel} alt="dark" /> : <img src={themeDarkNor} alt="dark" />}

                <Typography variant="body2" style={{ marginTop: 15 }}>
                  {"Dark Mode"}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 30 }} />
        {/* Clear Data */}
        <Grid container className={classes.container}>
          <Grid item style={{ width: 240 }}>
            <Typography className={classes.subtitle} variant="h2" align="left">
              {"Clear Web Data"}
            </Typography>
          </Grid>
          <Grid item className={classes.contents}>
            <Typography className={classes.description} variant="body1" align="left">
              {
                "Initialize all pages; initialization does not delete the backed up notebook data on your Google drive. It may take some time for the changes to be reflected."
              }
            </Typography>
            <Grid container direction="row-reverse" alignItems="center">
              <Box bgcolor="background.paper">
                <Button color="primary" variant="outlined" onClick={this.handleClickOpen}>
                  CLEAR DATA
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 30 }} />
        {/* Log Out */}
        <Grid container className={classes.container}>
          <Grid item style={{ width: 240 }}>
            <Typography className={classes.subtitle} variant="h2" align="left">
              {"Google Account"}
            </Typography>
            <Typography className={classes.subtitle} variant="h2" align="left">
              {"Logout"}
            </Typography>
          </Grid>
          <Grid item className={classes.contents}>
            <Typography className={classes.description} variant="body1" align="left">
              {"Log out of your"} <span style={{ color: "#1794b6" }}>{email}</span>{" "}
              {"account. You can log in again at any time."}
            </Typography>
            <Grid container direction="row-reverse" alignItems="center">
              <Box bgcolor="background.paper">
                <Button color="primary" variant="outlined" onClick={this.logout}>
                  Logout
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle disableTypography={true} id="alert-dialog-title">
            <Typography variant="h4">
              {"Are you sure you want to delete the data?"}            
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The note data will be permanently deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.deleteAllData}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        
      </div>
    );
  }

  getFilelist = () => {
    let drivenote = GDApi.getAllNotes2();
    drivenote.then(files => {
      console.log("filterd", files);
    });
  };
}
const mapStateToProps = state => {
  return {
    notes: state.notes.list,
    themeType: state.theme.type,
    user: state.login.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNotes: notes => dispatch(Actions.noteList(notes)),
    changeTheme: newtheme => dispatch(Actions.theme(newtheme))
  };
};

const styleView = withStyles(styles)(SettingView);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(styleView));
