import React from "react";
import { connect } from "react-redux";
import GoogleDriveDataStruct from "../../Data/GoogleDriveDataStruct";
import { withFirebase } from "../../Firebase";
import { CircularProgress, Divider } from "@material-ui/core";
import { MetaData, Database, Tag } from "../Utils";
import * as Actions from "../../store/actions";
import withWidth from "@material-ui/core/withWidth";
import { withRouter } from "react-router-dom";

import PageTopView from "./PageTopView";
import PageBottomView from "./PageBottomView";
import RenderingView from "./RenderingView";

const propTypes = {};

const defaultProps = {};

class PageDetailModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stroke: null,
      rect: null,
      bgurl: null,
      scale: 100,
      ratioWH: 1.414,
      page: null,
      scaleType: 1 // 1: ScaleTo: Height, 2 ScaleTo: Width
    };
  }

  loadPage = page => {
    console.log("loading page info", page);
    this.setState({ stroke: null, rect: null, bgurl: null });
    let pageinfo = {
      section: this.props.note.section,
      owner: this.props.note.owner,
      note: page.note,
      page: page.page
    };

    Database.getStroke(page.pageid)
      .then(querySnapshot => {
        let p = null;
        // doc 하나만 사용함 나중에 확장 가능성 있음
        querySnapshot.forEach((doc, index) => {
          p = doc.data();
          this.doc = doc
        });
        let data = p.stroke.toUint8Array();
        // console.log(data);
        let stroke = new GoogleDriveDataStruct(data);
        console.log(stroke);
        // console.log(strokeList)
        this.setState({ stroke: stroke.strokeList });
      })
      .catch(err => console.log(err));

    MetaData.getNoteSize(this.props.note)
      .then(rect => {
        console.log(rect);
        let ratio = rect.height / rect.width;
        console.log(rect, "ratio H / W:", ratio);
        this.setState({ rect: rect, ratioWH: ratio });
      })
      .catch(e => console.log(e));

    MetaData.getBackgroudImage(pageinfo, this.props.firebase)
      .then(url => {
        this.setState({ bgurl: url });
      })
      .catch(e => {
        console.log(e);
      });
  };

  goBack = () => {
    window.history.back();
  };

  saveHandle = () => {
    this.saveCallback();
  };

  saveBind = callback => {
    this.saveCallback = callback;
  };

  sharePage = (e) => {
    console.log("TODO: share page", this.props.page)
    console.log("Stroke list", this.state.stroke)
    console.log("doc info", this.doc)
    let page = this.props.page
    let stroke = this.state.stroke
    Tag.pagedetail("share", page.note)
    page.email = this.props.user.email
    page.updated = new Date().getTime()
    page.username = this.props.user.displayName
    page.photoURL = this.props.user.photoURL
    // Converting and uploading Page
    Database.sharePage(page, stroke).then(shareid => {
      console.log("shareid", shareid )
      const shareurl = 'https://sharestudio.web.app/?id=' + shareid
      const title = page.notetitle
      const text = page.page + " Page"
      console.log(title, text)
      if (navigator.share) {
        navigator.share({
            title: title,
            text: text,
            url: shareurl,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }else{
        console.log("not support share")
        var textField = document.createElement('textarea')
        textField.innerText = shareurl
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        alert("Copied.")
      }
    })
  }

  render() {
    console.log("page detail view");
    let isLoading = true;
    if (this.state.stroke && this.state.rect && this.state.bgurl) {
      isLoading = false;
    }
    const h = window.innerHeight;
    return (
      <div
        className="PageDetailView"
        style={{
          display: "flex",
          flexDirection: "column",
          height: h
        }}
      >
 
        <PageTopView
          goBack={this.goBack}
          pagelist={this.handleClickOpen}
          saveHandle={this.saveHandle}
          title={this.props.note.title}
          page={this.props.page.page}
          sharePage={this.sharePage}
        />

        <Divider />
        {isLoading ? <CircularProgress style={{margin: 'auto'}} /> :
          <RenderingView
            scaleType={this.state.scaleType}
            scale={this.state.scale}
            stroke={this.state.stroke}
            rect={this.state.rect}
            bgurl={this.state.bgurl}
            saveBind={this.saveBind}
            page={this.props.page}
            note={this.props.note}
            isLoading={isLoading}
          />
        }
        <Divider />

        <PageBottomView
          zoom={this.zoom}
          scale={this.state.scale}
          nextPage={this.props.nextPage}
          zoomAtWidth={this.zoomAtWidth}
        />
      </div>
    );
  }

  zoom = zoomin => {
    const scaleParam = this.state.scaleType === 1 ? [50, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400] :
    [25, 50, 75, 80, 90, 100, 110, 125, 150, 175, 200]
    let scale = null;
    if (zoomin) {
      scale = scaleParam.filter(v => v > this.state.scale).shift();
      Tag.pagedetail("big")
    } else {
      scale = scaleParam.filter(v => v < this.state.scale).pop();
      Tag.pagedetail("small")
    }
    if (scale) {
      this.setState({ scale: scale });
    }
  };

  // Zoom to With or height
  zoomAtWidth = width => {
    if (width) {
      Tag.pagedetail("widthfit")
      this.setState({ scale: 100, scaleType: 2 });
    } else {
      Tag.pagedetail("heightfit")
      this.setState({ scale: 100, scaleType: 1 });
    }
  };

  save = () => {
    Tag.pagedetail("save")
    this.setState({ save: this.state.save + 1 });
  };

  componentDidMount() {
    if (this.props.page) {
      this.loadPage(this.props.page);
    }
  }

  shouldComponentUpdate(nextPros, nextState) {
    //Page change
    if (this.props.page !== nextPros.page) {
      this.setState({ stroke: null });
      this.loadPage(nextPros.page);
      return false;
    }
    return true;
  }
}

PageDetailModel.propTypes = propTypes;
PageDetailModel.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    note: state.note.currentNote,
    page: state.page.currentPage,
    user: state.login.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    nextPage: isNext => dispatch(Actions.movePage(isNext))
  };
};

const withFirePageDetailView = withRouter(withFirebase(PageDetailModel));
const withWidthView = withWidth()(withFirePageDetailView);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidthView);
