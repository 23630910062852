export default class GoogleDriveDataStruct {
  constructor(d) {
    this.startSting = ""
    this.version = 0
    this.noteType = 0
    this.pageNumber = 0
    this.noteWidth = 0
    this.noteHeigth = 0
    this.createdDate = 0
    this.lastModifiedDate = 0
    this.dirtyBit = 0
    this.strokeList = []
    this.pageGuidCount = 0
    this.pageGuid = ""

    this.startSting = new TextDecoder("utf-8").decode(d.slice(0,3));
    this.version = d[3]
    console.log("Data Version: ", this.version)

    this.noteType = byteArrayToInt(d.slice(7,11))
    this.pageNumber = byteArrayToInt(d.slice(11,15))
    this.noteWidth = toFloat(d, 15)
    this.noteHeigth = toFloat(d, 19)
    this.createdDate = toDate64(d,23)
    this.lastModifiedDate = toDate64(d, 31)

    this.dirtyBit = d[39]
    let strokeCount = toUInt32(d, 40)
    
    //Version Set
    var scale = 1
    let extraDataLength = 19
    if (this.version < 4) {
        scale = Math.max(this.noteWidth, this.noteHeigth)
        //TODO: version 3일 경우 
//            extraDataLength = 20
    }
    console.log('Stroke Count', strokeCount ,'extraLen', extraDataLength, 'scale', scale)

    var index = 44

    for (let i = 0; i<strokeCount; i++) {
        // Type(1), 시간(8), 파일명(60), status(1), 노트id(4), 노트uuid(30), 페이지번호(4) - 추가 설명 IOS에서 사용하는 VM데이터 길이
        if (d[index] === 1) {
            index += (1 + 8 + 60 + 1 + 4 + 30 + 4)
            continue
        }
        let dotStart = index
        let dotSize = toUInt32(d, index+6) * 13
        let extraSize = d[index+dotSize+18]
        let dotEnd = (index + dotSize + extraSize + extraDataLength)
        let strokeBlob = d.slice(dotStart,dotEnd)

        var st = new StrokeData(strokeBlob, scale)
        st.pageInfo = {
          s:3, o:27, n:618, p:this.pageNumber
        }
        this.strokeList.push(st)
        index = dotEnd
    }
    this.pageGuidCount = toUInt32(d, index)
    index += 5
    if (this.pageGuidCount > 0) {
        this.pageGuid = toString(d.slice(index,(index + this.pageGuidCount)))
    }
  }

}

class StrokeData {
  constructor(data, scale) {
    this.penTipType = 0;
    this.penTipColor = [0, 0, 0, 0];
    this.penThickness = 1;
    this.dotCount = 0;
    this.time = 0;
    this.dotData = [];
    this.extraData = [];
    this.scale = scale;
    this.pageInfo = { s: 0, o: 0, n: 0 };

    let d = data;
    this.penTipType = d[0];
    this.penTipColor = d.slice(1, 5);
    this.penThickness = d[5];
    this.dotCount = toUInt32(d, 6);
    let dotSize = this.dotCount * 13;
    this.time = toDate64(d, 10);
    this.dotData = d.slice(18, 18 + dotSize);
    let extraCount = d[18 + dotSize];
    if (extraCount > 0) {
      this.extraData = d.slice(19 + dotSize, 19 + dotSize + extraCount);
    }
  }

  /**
   * Returns 'rgba(255,255,255,1)'
   * @returns {string}
   */
  getColor() {
    let color = this.penTipColor;
    let r = color[2];
    color[2] = color[0];
    color[0] = r;
    let rgb = color.slice(0, 3);
    let a = color[3] / 255;
    let hexColor = "rgba(" + rgb.toString() + "," + a + ")";
    return hexColor;
  }

  getDots() {
    var dots = [];
    var time = this.time;
    let pencolor = this.penTipColor;

    let dotBlob = this.dotData;
    for (let i = 0; i < this.dotCount; i++) {
      let st = i * 13;
      let end = st + 13;
      let d = dotBlob.slice(st, end);
      let x = toFloat(d, 0) * this.scale;
      let y = toFloat(d, 4) * this.scale;
      let force = toFloat(d, 8);
      let deltaTime = d[12];
      var dot = {};
      dot.pageInfo = this.pageInfo;
      time += deltaTime;
      dot.time = time;
      dot.penTipType = this.pentype;
      dot.penTipColor = pencolor;
      dot.force = force;
      dot.x = x;
      dot.y = y;
      dot.fx = (x * 100) % 100;
      dot.fy = (y * 100) % 100;
      dots.push(dot);
    }
    return dots;
  }
}


export function toUTF8Array(str) {
  var utf8 = [];
  for (var i = 0; i < str.length; i++) {
      var charcode = str.charCodeAt(i);
      if (charcode < 0x80) utf8.push(charcode);
      else if (charcode < 0x800) {
          utf8.push(0xc0 | (charcode >> 6),
              0x80 | (charcode & 0x3f));
      }
      else if (charcode < 0xd800 || charcode >= 0xe000) {
          utf8.push(0xe0 | (charcode >> 12),
              0x80 | ((charcode >> 6) & 0x3f),
              0x80 | (charcode & 0x3f));
      }
      // surrogate pair
      else {
          i++;
          // UTF-16 encodes 0x10000-0x10FFFF by
          // subtracting 0x10000 and splitting the
          // 20 bits of 0x0-0xFFFFF into two halves
          charcode = 0x10000 + (((charcode & 0x3ff) << 10)
              | (str.charCodeAt(i) & 0x3ff));
          utf8.push(0xf0 | (charcode >> 18),
              0x80 | ((charcode >> 12) & 0x3f),
              0x80 | ((charcode >> 6) & 0x3f),
              0x80 | (charcode & 0x3f));
      }
  }
  return utf8;
}

export function byteArrayToInt(bytes) {
  let arr = new Uint8Array(bytes)
  let dv = new DataView(arr.buffer)
  return dv.getInt32(0, true)
}

export function intToByteArray(input) {
  let arr = new Uint8Array(4)
  let dv = new DataView(arr.buffer)
  dv.setUint32(0, input, true)
  return Array.from(arr)
}

export function byteArrayToShort(bytes) {
  let arr = new Uint8Array(bytes)
  let dv = new DataView(arr.buffer)
  return dv.getInt16(0, true)
}

export function shortToByteArray(input) {
  let arr = new Uint8Array(2)
  let dv = new DataView(arr.buffer)
  dv.setUint16(0, input, true)
  return Array.from(arr)
}

export function byteArrayToLong(bytes) {
  var byte = new Uint8Array(bytes)
  var view = new DataView(byte.buffer)
  var hi = view.getUint32(0, true)
  let low = view.getUint32(4,true)
  var intValue = hi + (low * 4294967296) // 2 ^ 32
  return intValue
}

export function longToByteArray(input) {
  let long = input
  var byteArray = [0, 0, 0, 0, 0, 0, 0, 0];
  for ( var index = 0; index < byteArray.length; index ++ ) {
      var byte = long & 0xff;
      byteArray[index] = byte;
      long = (long - byte) / 256 ;
  }
  return Array.from(byteArray)
}

function toFloat(d, index) {
  var byte = d.slice(index, index+4)
  var view = new DataView(byte.buffer);
  return view.getFloat32(0, true)
}

// function toUInt64(d, index) {
//   var byte = d.slice(index, index+8)
//   var view = new DataView(byte.buffer);
//   return view.getFloat64(0, true)
// }

function toUInt32(d, index) {
  var byte = d.slice(index, index+4)
  var view = new DataView(byte.buffer);
  return view.getUint32(0,true)
}

function toDate64(d, index) {
  var byte = d.slice(index, index+8)
  var view = new DataView(byte.buffer)
  var hi = view.getUint32(0, true)
  let low = view.getUint32(4,true)
  var intValue = hi + (low * 4294967296) // 2 ^ 32
  return new Date(intValue)
}