import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Drawer, Paper, Typography, Divider, Box } from "@material-ui/core";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { withStyles } from "@material-ui/core/styles";

const propTypes = {};

const defaultProps = {};

const styles = theme => ({
  paper: {
    width: 142,
    height: 217,
    border: "1px ridge",
    "&:hover": {
      border: "1px ridge #1794b6"
    }
  },
  currentPage: {
    color: theme.palette.primary.main
  }
});

class ModalPageList extends React.Component {
  handleListItemClick = page => {
    console.log(page);
    this.props.onClose();
    this.props.selectPage(page);
  };

  render() {
    const { classes, open, pages, title, currentPage } = this.props;
    console.log(currentPage)
    return (
      <Drawer open={open} onClose={this.props.onClose} anchor="right" aria-labelledby="page-list-dialog">
        <Box bgcolor="background.paper">
          <Typography variant="h3" align="center" style={{ marginTop: 20 }}>
            {title}
          </Typography>
          <Divider style={{ marginTop: 20 }} />

          <List style={{ width: 200 }}>
            {pages.map(page => (
              <ListItem key={page.page} onClick={() => this.handleListItemClick(page)}>
                <div style={{ margin: "auto" }}>
                  <Paper elevation={0} square={true} className={classes.paper}>
                    {page.thumbnail ? <img src={page.thumbnail} alt="" width="140" height="215" /> : <div />}
                  </Paper>
                  {page.pageid === currentPage.pageid ? (
                    <Typography className={classes.currentPage} variant="subtitle1" style={{ marginLeft: 20 }}>
                      {page.page} Page
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" style={{ marginLeft: 20 }}>
                      {page.page} Page
                    </Typography>
                  )}
                </div>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    );
  }
}

ModalPageList.propTypes = propTypes;
ModalPageList.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    pages: state.pages.list,
    currentPage: state.page.currentPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPage: page => dispatch(Actions.changePage(page))
  };
};

const styledView = withStyles(styles)(ModalPageList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styledView);
