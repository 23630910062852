import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Typography, Paper, ButtonBase } from "@material-ui/core";

const propTypes = {
  page: PropTypes.object,
  selectPage: PropTypes.func,
  timeLine: PropTypes.bool
};

const defaultProps = { timeLine: false };

const styles = theme => ({
  hover: {
    position: "relative",
    marginBottom: 10,
    "&:hover": {
      zIndex: 1200,
      transform: "scale(1.2)",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)" // elevation 5
    }
  }
});

class PageView extends React.Component {
  state = {
    width: 178,
    height: 254
  };

  render() {
    const { classes, page, selectPage, timeLine } = this.props;

    if (page.thumbnail) {
      // console.log(page.thumbnail.length)
    }
    const { width, height } = this.state;
    let pageDescript = page.page + " Page";

    return (
      <ButtonBase onClick={() => selectPage(page)} style={{ margin: 8 }}>
        <div>
          <Paper className={classes.hover} elevation={1} square>
            {page.thumbnail ? (
              <img src={page.thumbnail} alt="" width={width} height={height} />
            ) : (
              <img src="../../assets/thumnail/s1.png" alt="" width={width} height={height} />
            )}
          </Paper>
          {timeLine && (
            <Typography variant="body2" align="left" >
              {page.notetitle}
            </Typography>
          )}
          <Typography variant="body2" align="left" style={{ color: "#666666"}}>
            {pageDescript}
          </Typography>
        </div>
      </ButtonBase>
    );
  }

  componentDidMount() {
    if (window.innerWidth < 600) {
      let w = window.innerWidth - 16 * 3;
      this.setState({ width: w / 2, height: (w / 2) * 1.414 });
    }
  }
}

PageView.propTypes = propTypes;
PageView.defaultProps = defaultProps;

export default withStyles(styles)(PageView);
