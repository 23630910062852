import * as types from '../actions/ActionTypes'

const initialState = {
  currentPage: null,
}

export default function page(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_PAGE:
      return {currentPage: action.page}
    default:
      return state
  }
}