import React from "react";
import DefaultHeader from "./DefaultHeader";
import DefaultSider from "./DefaultSider";
import DefaultMain from "./DefaultMain";

import CssBaseline from "@material-ui/core/CssBaseline";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../routes";

import * as Actions from "../store/actions";
import { connect } from "react-redux";
import { GDApi } from "../views/Utils";

class DefaultLayoutModel extends React.Component {
  handleDrawerOpen = () => {
    this.props.handleDrawer(!this.props.open);
  };

  handleDrawerClose = () => {
    this.props.handleDrawer(false);
  };

  logout = () => {
    GDApi.logout();
    this.props.firebase
      .doSignOut()
      .then(() => {
        console.log("logout ");
      })
      .catch(error => {
        console.log(error);
      });
  };

  login = () => {
    console.log("go login page");
    GDApi.login();
    // this.props.history.push(ROUTES.MAIN_TIMELINE)
  };

  render() {
    const { open } = this.props;
    return (
      <div className="defaultLayout" style={{ display: "flex", position: 'relative' }}>
        <DefaultHeader handleDrawerOpen={this.handleDrawerOpen} open={open} />
        <CssBaseline />
        <DefaultSider user={this.props.user} open={open} handleDrawerClose={this.handleDrawerClose} />
        <DefaultMain open={open} />
      </div>
    );
  }

  shouldComponentUpdate(nextProp) {
    if (!nextProp.user) {
      this.props.history.push(ROUTES.HOME);
    }
    return true;
  }

  componentDidMount() {
    console.log("default layout mount", this.props.user);
    if (this.props.user) {
      this.props.history.push(ROUTES.MAIN);
    } else {
      this.props.history.push(ROUTES.HOME);
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    open: state.drawer.open
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleDrawer: open => dispatch(Actions.drawer(open))
  };
};

const withDefaultLayout = withRouter(withFirebase(DefaultLayoutModel));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDefaultLayout);
