import React from "react";
import { Typography } from "@material-ui/core";
const timelineSVG = "../../assets/img/timeline.svg"
const NoDataView = () => {
  return (
    <div style={{ marginLeft: 120, marginTop: 120, display: 'flex', flexDirection: "column"}}>
      <img src={timelineSVG} alt=":'-("  width="102" height="76" style={{marginRight: "auto"}}></img>

      <Typography variant='h1' align="left" style={{ marginTop: 80}}>
        {"OOPS !"}
      </Typography>
      <Typography variant='h1' align="left" style={{marginTop: 10 }}>
        {"There's no record."}
      </Typography>

      <Typography variant='subtitle1' align="left" style={{ marginTop: 50, color: "#aaa", fontWeight:500 }}>
        {" Get the laptop on the Google Drive via the"} <span style={{color:"#1794b6"}}> {"NoteBook"}</span>
      </Typography>
    </div>
  );
};

export default NoDataView;
