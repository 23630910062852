import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import DriveDownloadView from "./DriveDownloadView"
const ImportSVG = "../../../assets/img/importGD.svg";

const propTypes = {
  title: PropTypes.string,
  pages: PropTypes.array,
  completed: PropTypes.number, // 0 to 100
  progressInfo: PropTypes.string,
  handelCancel: PropTypes.func
};

const defaultProps = {};

const styles = theme => ({
  googledrive: {
    marginLeft: 'calc(12%)',
    marginTop: 100,
    display: "flex",
    flexDirection: "column",
    minWidth: 300
  },
  dialog: {
    height: 500,
    margin: "0 auto",
    position: "relative",
    overflowX: "hidden",
    backgroundColor: theme.palette.background.default
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center", //"space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    width: "100%"
  }
});

class DriveListView extends React.Component {
  state = {
    open: false
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleEntering = () => {
    console.log("handleEntering");
  };

  migrationStart = () => {
    this.setState({ open: true });
    this.props.migrationNote();
  };

  cancel = () => {
    console.log("Data import Cancel");
    this.handleClose();
    this.props.handelCancel();
  };

  render() {
    const { classes, title, activeStep} = this.props;
    let { open } = this.state;
    if (activeStep === 5) {
      open = false;
    }
    const dialogWidth = Math.min(window.innerWidth * 0.8, 600);
    console.log("dialogWidth", dialogWidth)
    return (
      <div className={classes.googledrive}>
        <img src={ImportSVG} alt="Google Drive" width="130" height="105" style={{ marginRight: "auto" }} />

        <Typography variant="h1" align="left" style={{ marginTop: 70 }}>
          {"Import"}
        </Typography>

        <Typography variant="h1" align="left" style={{marginTop: 10}}>
          <span style={{ color: "#1794b6" }}> {title} </span> {" Notebook"}
        </Typography>

        <Typography variant="subtitle1" align="left" style={{ marginTop: 25, color: "#aaa", fontWeight: 500 }}>
          {"Import the Notebook on your Google Drive."}
        </Typography>

        <Button color="primary" variant="contained" onClick={this.migrationStart} style={{ boxShadow:"none", marginTop: 33, width: 165}} >
          Google Drive
        </Button>

        {/* Google Drive Dialog */}
        <DriveDownloadView 
            title={title}
            error={this.props.error}
            activeStep={activeStep}
            open = {open}
            handleClose = {this.handleClose}
            handleEntering={this.handleEntering}
            dialogWidth = {dialogWidth}
            cancel= {this.cancel} 
            progress={this.props.progress}
        />
      </div>
    );
  }
}

DriveListView.propTypes = propTypes;
DriveListView.defaultProps = defaultProps;

export default withStyles(styles)(DriveListView);
